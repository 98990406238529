import React, {useState, useEffect} from 'react';
import {Box} from '@material-ui/core';
import useStyles from './index.style';
import {useDispatch, useSelector} from 'react-redux';
import CmtCard from "../../../@coremat/CmtCard";
import CmtCardHeader from "../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../@coremat/CmtCard/CmtCardContent";
import ChampionshipCell from './ChampionshipCell'

const ListVIew = ({championshipList, removeChampionship, publishChampionship}) => {

  const classes = useStyles();
  const dispatch = useDispatch();



  return(
    <Box className={classes.championshipListContainer}>
      {
        championshipList !== undefined && championshipList.map((championship, id) =>{
          return <ChampionshipCell publishChampionship={publishChampionship} championshipData={championship} key={id} removeChampionship={removeChampionship} />
        })
      }
    </Box>
  )
}

export default ListVIew;
