import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../components/Common/formElements/AppTextInput';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../../constants/ErrorMessages';
import { createBar, onUpdateBar } from '../../../../redux/actions/BarApp';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.dark,
    },
  },
}));

function NumberFormatCustom(props) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      onValueChange={values => {
        onChange(values.formattedValue);
      }}
      format="##.##.####"
    />
  );
}



const CreateBar = ({ open, handleDialog }) => {
  const { currentBar } = useSelector(({ barApp }) => barApp);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [profilePic, setprofilePic] = useState(currentBar ? currentBar.profilePic : '');
  const [name, setName] = useState(currentBar ? currentBar.name : '');
  const [website, setWebsite] = useState(currentBar ? currentBar.website : '');
  const [street, setStreet] = useState(currentBar ? currentBar.street : '');
  const [number, setNumber] = useState(currentBar ? currentBar.number : '');
  const [state, setState] = useState(currentBar ? currentBar.state : '');
  const [postalCode, setPostalCode] = useState(currentBar ? currentBar.postalCode : '');
  const [city, setCity] = useState(currentBar ? currentBar.city : '');
  const [country, setCountry] = useState(currentBar ? currentBar.country : '');
  const [phone, setPhone] = useState(currentBar ? currentBar.phone : '');
  const [email, setEmail] = useState(currentBar ? currentBar.email : '');
  const [facebook, setFacebook] = useState(currentBar ? currentBar.facebook : '');

  const [nameError, setNameError] = useState('');
  const [countryError, setCountryError] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setprofilePic(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const checkValidations = () => {
    if (!name) {
      setNameError(requiredMessage);
    } else if (!country) {
      setCountryError(requiredMessage);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const Bar = {
      name: `${name}`,
      website,
      phone,
      email,
      rating: `0`,
      country,
      city,
      folder: `accepted`,
      profilePic,
      state,
      street,
      postalCode,
      facebook,
      number,
      dates: [{ date: '28.03.2012 10:32:30', label: 'start_date' }],
      matchType: `Group C`,
      starred: false,
      frequent: true,
      competition: `Serie A`,
      labels: [1]

    };
    if (currentBar) {
      dispatch(onUpdateBar({ ...currentBar, ...Bar }));
    } else {
      dispatch(createBar(Bar));
    }
    handleDialog();
  };

  return (
    <Dialog open={open} onClose={handleDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentBar ? 'Edit Bar Details' : 'Create New Bar'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <Box {...getRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className="pointer">
            <input {...getInputProps()} />
            <CmtAvatar size={70} src={profilePic} />
          </Box>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Bar Name"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                  setNameError('');
                }}
                helperText={nameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Bar Website"
                value={website}
                onChange={e => setWebsite(e.target.value)}
              />
            </Grid>
          </GridContainer>
        </Box>
        <GridContainer>
          <Grid item xs={12} sm={5}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Street"
              value={street}
              onChange={e => {
                setStreet(e.target.value);
              }}
              helperText={countryError}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Number"
              value={number}
              onChange={e => {
                setNumber(e.target.value);
              }}
              helperText={countryError}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="State"
              value={state}
              onChange={e => {
                setState(e.target.value);
              }}
              helperText={countryError}
            />
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={12} sm={4}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Postal Code"
              value={postalCode}
              onChange={e => {
                setPostalCode(e.target.value);
              }}
              helperText={countryError}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="City"
              value={city}
              onChange={e => {
                setCity(e.target.value);
              }}
              helperText={countryError}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Country"
              value={country}
              onChange={e => {
                setCountry(e.target.value);
                setCountryError('');
              }}
              helperText={countryError}
            />
          </Grid>
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={4}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Phone Number"
              value={phone}
              onChange={e => {
                setPhone(e.target.value);
              }}
              helperText={countryError}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Email"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              helperText={countryError}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Facebook"
              value={facebook}
              onChange={e => {
                setFacebook(e.target.value);
              }}
              helperText={countryError}
            />
          </Grid>
        </GridContainer>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={handleDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={checkValidations}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateBar;

CreateBar.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedBar: PropTypes.object,
};

CreateBar.defaultProps = {
  selectedBar: null,
};
