import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import QrReader from 'react-qr-reader';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { scanQrCode } from '../../../redux/actions/QrCode';
import Button from '@material-ui/core/Button';
import { SCAN_QR_CODE, VALID_QR_CODE } from '../../../constants/ActionTypes';

const ScanQrCode = () => {
  const { qrDetail, isValid } = useSelector(({ qrCode }) => qrCode);
  const [result, setResult] = useState('Please Scan QR code for prize');
  const [qrText, setQrText] = useState(null);
  const [qrTextInvalid, setQrTextInvalid] = useState(null);
  const [typeResult, setTypeResult] = useState('error');
  const dispatch = useDispatch();

  const useStyles = makeStyles(theme => ({
    inBuildAppCard: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      margin: '15px',
      padding: '25px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
      position: 'relative',
      overflow: 'hidden',
      maxWidth: '425px',
      '& .error': {
        color: 'red',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      '& .success': {
        color: 'green',
        fontWeight: 'bold',
        fontSize: '18px',
      },
    },
  }));
  const classes = useStyles();
  const handleScan = data => {
    if (typeResult !== 'success') {
      if (data) {
        setResult(data);
        let body = {
          challengeWinnerId: data,
        };
        dispatch(scanQrCode(body, isValid));
      }
    }
  };
  const handleError = err => {
    console.error(err);
  };
  const resetScanner = () => {
    setQrText(null);
    setTypeResult('error');
    dispatch({ type: VALID_QR_CODE, payload: false });
    dispatch({ type: SCAN_QR_CODE, payload: null });
  };
  useEffect(() => {
    if (isValid === true) {
      setTypeResult('success');
    } else {
      setTypeResult('error');
    }
  }, [isValid]);
  return (
    <Box component="div" className={classes.inBuildAppCard}>
      <QrReader delay={2000} onError={handleError} onScan={handleScan} style={{ maxWidth: '375px' }} />
      <p style={{ marginTop: '30px', marginBottom: '30px' }} className={typeResult}>
        {qrDetail}
      </p>

      <Button variant="contained" color="primary" component="span" onClick={e => resetScanner()}>
        Reset Scanner
      </Button>
    </Box>
  );
};

export default ScanQrCode;
