import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../index.style';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import CmtList from '../../../../@coremat/CmtList';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import { getMatchCounts, getLabelsList, setFilterType } from '../../../../redux/actions/MatchApp';
import ItemCell from './ItemCell';
import AddLabel from './AddLabel';
// import { foldersList } from '../../../../@fake-db/matches';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withWidth } from '@material-ui/core';
import { getAppSidebarHeight } from '../../../../constants/AppConstants';
import MoreOptions from './MoreOptions';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AppContext from '../../../../components/contextProvider/AppContextProvider/AppContext';
import LabelCell from './LabelCell';

import StarIcon from '@material-ui/icons/Star';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';

export const foldersList = [
  { id: 1, name: 'Future', slug: 'future', icon: <AccessTimeIcon /> },
  // { id: 2, name: 'Popular', slug: 'live', icon: <TimelapseIcon /> },
  // { id: 3, name: 'Ended', slug: 'past', icon: <AlarmOnIcon /> },
];

const Sidebar = ({ onClickCreateMatch, width }) => {
  const { isSideBarCollapsed, labelsList, filterType, matchesList } = useSelector(({ matchApp }) => matchApp);
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getMatchCounts());
  }, [dispatch, matchesList]);

  useEffect(() => {
    dispatch(getLabelsList());
  }, [dispatch]);

  const onChangeFolder = folder => {
    dispatch(
      setFilterType({
        status: folder,
        competition: '',
        query: '',
      }),
    );
  };

  const onChangeLabel = label => {
    dispatch(
      setFilterType({
        status: '',
        competition: label,
        query: '',
      }),
    );
  };

  const classes = useStyles({
    isCollapsed: false,
    height: getAppSidebarHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      <Box className={classes.inBuildAppSidebarHeader}>
        {/*<Button className={classes.addOfficialChampionshipBtn} variant="contained" color="primary" onClick={onClickCreateMatch}>*/}
        {/*  <PersonAddIcon />*/}
        {/*  <Box component="span" className="add-officialChampionship-btn-text">*/}
        {/*    Create New*/}
        {/*  </Box>*/}
        {/*</Button>*/}
      </Box>

      <PerfectScrollbar className={classes.perfectScrollbarMatchSidebar}>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={foldersList}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterType.status}
                onChange={onChangeFolder}
              />
            )}
          />

          <ListItem component="div" className={classes.appNavHeaderItem}>
            <Box component="span" className={classes.appNavHeaderItemText}>
              Competitions
            </Box>
          </ListItem>

          <CmtList
            data={labelsList}
            renderRow={(item, index) => (
              <LabelCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterType.competition}
                onChange={onChangeLabel}
              />
            )}
          />

          {/*<AddLabel />*/}

          {/*<MoreOptions classes={classes} matchesList={matchesList} />*/}
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(Sidebar);

Sidebar.prototype = {
  onClickCreateMatch: PropTypes.func,
};
