import mock from '../mockConfig';
import { idGenerator } from '../../utils/commonHelper';
import { matches, foldersList, labelsList } from '../../@fake-db/matches';

let labels = labelsList;
let matchesList = matches;

mock.onGet('/match/labels').reply(200, labelsList);

mock.onPost('/match/labels').reply(request => {
  const { label } = JSON.parse(request.data);
  let newLabel = { ...label, id: idGenerator(), slug: label.name.toLowerCase() };
  labels = labels.push(newLabel);
  return [200, newLabel];
});

mock.onPut('/match/labels').reply(request => {
  const { label } = JSON.parse(request.data);
  labels = labels.map(item => (item.id === label.id ? label : item));
  return [200];
});

mock.onPut('/match/labels/delete').reply(request => {
  const { labelId } = JSON.parse(request.data);
  labels = labels.filter(item => item.id !== labelId);
  return [200];
});

mock.onGet('/match').reply(config => {
  const { params } = config;
  const { selectedFolder, selectedLabel, searchText } = params;
  let folderMatches = [];

  if (searchText) {
    folderMatches = matchesList.filter(match => {
      return (
        match.name.toLowerCase().includes(searchText.toLowerCase()) ||
        match.country.toLowerCase().includes(searchText.toLowerCase()) ||
        match.competition.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }

  if (selectedFolder) {
    if (selectedFolder === 'starred') {
      folderMatches = matchesList.filter(match => match.starred);
    } else if (selectedFolder === 'frequent') {
      folderMatches = matchesList.filter(match => match.frequent);
    } else {
      folderMatches = matchesList.filter(match => match.folder === selectedFolder);
    }
  }

  if (selectedLabel) {
    folderMatches = matchesList.filter(match => match.labels.includes(selectedLabel));
  }

  const total = folderMatches.length;

  return [200, { folderMatches, total }];
});

mock.onPut('/match/update-starred').reply(request => {
  const { matchIds, status } = JSON.parse(request.data);
  matchesList = matchesList.map(match => {
    if (matchIds.includes(match.id)) {
      match.starred = status;
      return match;
    } else {
      return match;
    }
  });
  return [200];
});

mock.onPut('/match/delete').reply(request => {
  const { matchIds } = JSON.parse(request.data);
  matchesList = matchesList.map(match => {
    if (matchIds.includes(match.id)) {
      match.folder = 'trash';
      return match;
    } else {
      return match;
    }
  });
  return [200];
});

mock.onPut('/match/update-label').reply(request => {
  const { matchIds, label } = JSON.parse(request.data);
  matchesList = matchesList.map(match => {
    if (matchIds.includes(match.id)) {
      const newLabel = match.labels.find(item => item === label);
      if (newLabel) {
        match.labels = match.labels.filter(item => item !== label);
      } else {
        match.labels = match.labels.concat(label);
      }
      return match;
    } else {
      return match;
    }
  });
  const updatedMatches = matchesList.filter(match => matchIds.includes(match.id));
  return [200, updatedMatches];
});

mock.onPost('/match').reply(request => {
  const { match } = JSON.parse(request.data);
  const newMatch = {
    id: idGenerator(),
    frequent: false,
    starred: false,
    labels: [],
    folder: 'matches',
    ...match,
  };
  matchesList = [newMatch, ...matchesList];
  return [200, newMatch];
});

mock.onPut('/match').reply(request => {
  const { match } = JSON.parse(request.data);
  matchesList = matchesList.map(item => (item.id === match.id ? match : item));
  return [200];
});

// mock.onGet('/match/counter').reply(config => {
//   const counter = { folders: {}, labels: {} };
//   foldersList.map(item => {
//     if (item.slug === 'starred') {
//       counter.folders[item.id] = matchesList.filter(match => match.starred).length;
//     } else if (item.slug === 'frequent') {
//       counter.folders[item.id] = matchesList.filter(match => match.frequent).length;
//     } else {
//       counter.folders[item.id] = matchesList.filter(match => match.folder === item.slug).length;
//     }
//     return null;
//   });
//
//   labelsList.map(item => {
//     counter.labels[item.id] = matchesList.filter(match => match.labels.includes(item.id)).length;
//     return null;
//   });
//
//   return [200, counter];
// });
