import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';
import { createOfficialChampionship, getOfficialChampionshipList } from '../../../redux/actions/OfficialChampionship';
import Button from '@material-ui/core/Button';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';

import ChampionshipDetails from './ChampionshipDetails';
import Prizes from './Prizes';
import Questions from './Questions';
import Matches from './Matches';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {
  getLangForOfficialChampionship,
  patchOfficialChampionshipDetail,
  getMatchesForOfficialChampionship,
  getOfficialChampionshipDetail,
} from '../../../redux/actions/OfficialChampionship';
import { useParams } from 'react-router';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { fetchError } from '../../../redux/actions';
import { GET_OFFICIAL_CHAMPHIONSHIPS_DETAILS } from '../../../constants/ActionTypes';

const OfficialChampionship = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { lang, officialChampionshipMatches, officialChampionshipDetail, successCreate } = useSelector(
    ({ officialChampionship }) => officialChampionship,
  );

  const [viewDetail, setViewDetail] = useState(officialChampionshipDetail);
  const [langChamp, setLangChamp] = useState(
    lang !== undefined
      ? () => {
          let languageArray = [];
          lang.map((item, ley) => {
            languageArray.push(item.code);
          });
          return languageArray;
        }
      : [],
  );

  const [created, setCreated] = useState(false);

  const [httpImages, setHttpImages] = useState(false);

  const [matchesList, setMatchesList] = useState(undefined);
  const [challengeId, setChallengeId] = useState(null);

  const [logo, setLogo] = useState();
  const [cover, setCover] = useState();
  const [name, setName] = useState();
  const [stake, setStake] = useState();
  const [questions, setQuestions] = useState([]);
  const [matches, setMatches] = useState([]);
  const [matchesInsert, setMatchesInsert] = useState([]);
  const [matchesRemove, setMatchesRemove] = useState([]);

  const [daily, setDaily] = useState(0);
  const [weekly, setWeekly] = useState(0);
  const [allTime, setAllTime] = useState(1);
  const [qr, setQr] = useState(false);

  const [prizes, setPrizes] = useState([]);
  const [uuidPhotos, setUuidPhotos] = useState([]);
  const [howItWorks, setHowItWorks] = useState([]);

  useEffect(() => {
    dispatch(getLangForOfficialChampionship());
    dispatch(getMatchesForOfficialChampionship());
    if (id !== 'new') {
      dispatch(getOfficialChampionshipDetail(id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (created == true) {
      dispatch(fetchError('Official championship created.'));
      history.push('/official-championships');
    }
  }, [successCreate]);

  useEffect(() => {
    let langCodes = [];
    if (lang !== undefined) {
      lang.forEach((item, key) => {
        langCodes.push(item.code);
      });
    }
    setLangChamp(langCodes);
    setMatchesList(officialChampionshipMatches);
    setViewDetail(officialChampionshipDetail);
  }, [officialChampionshipMatches, officialChampionshipDetail, lang]);

  useEffect(() => {
    if (viewDetail !== undefined) {
      setLogo(viewDetail.logo);
      setCover(viewDetail.cover);
      setName(viewDetail.name);
      setStake(viewDetail.stake);

      setChallengeId(id);

      let newQuestionsList = [];
      viewDetail.questions.forEach((item, key) => {
        newQuestionsList.push({ ...item, deleted: false });
      });
      setQuestions(newQuestionsList);

      let newPrizesList = [];
      viewDetail.prizes.forEach((item, key) => {
        newPrizesList.push({ ...item, deleted: false });
      });
      setPrizes(newPrizesList);
      setMatches(viewDetail.matches);
      setHowItWorks(viewDetail.howItWorks);
    }
  }, [viewDetail]);

  const createNotification = (type, message) => {
    return NotificationManager.error(message, 'ERROR!', 2000);
  };

  const getPrizes = (getPrizesList, uuidList) => {
    setPrizes(getPrizesList);
    setUuidPhotos(uuidList);
  };

  const getHowItWorks = data => {
    setHowItWorks(data);
  };

  const getLogo = logo => {
    setLogo(logo);
  };

  const getCover = cover => {
    setCover(cover);
  };

  const getQuestions = question => {
    setQuestions(question);
  };

  const getName = newName => {
    setName(newName);
  };

  const getStake = newStake => {
    setStake(newStake);
  };

  const getMatches = (currentList, toBeInserted, toBeRemoved) => {
    setMatches(currentList);
    setMatchesInsert(toBeInserted);
    setMatchesRemove(toBeRemoved);
  };

  const getDaily = newDaily => {
    setDaily(newDaily);
  };

  const getWeekly = newWeekly => {
    setWeekly(newWeekly);
  };

  const getAllTime = newAllTime => {
    setAllTime(newAllTime);
  };

  const getQr = newQr => {
    setQr(newQr);
  };

  const create = type => {
    let nameEmpty = false;
    let stakeEmpty = false;
    let logoEmpty = false;
    let coverEmpty = false;
    let prizeEmpty = false;
    let questionEmpty = false;
    let matchesEmpty = false;
    let howItworksEmpty = false;

    let allTimeEmpty = false;
    let dailyEmpty = false;
    let weeklyEmpty = false;

    if (howItWorks.length === 0) {
      createNotification('error', 'How it works should not be empty');
      howItworksEmpty = true;
    } else {
      howItWorks.forEach((item, key) => {
        if (item.text.length === 0) {
          howItworksEmpty = true;
        }
      });
      if (howItworksEmpty === true) {
        createNotification('error', 'How it works should not be empty');
      }
    }
    if (name === undefined || name.length === 0) {
      createNotification('error', 'Championship Name should not be empty');
      nameEmpty = true;
    }

    if (allTime < 1) {
      createNotification('error', 'All Time Prizes should be bigger than 0');
      allTimeEmpty = true;
    }

    if (daily < 0) {
      createNotification('error', 'Daily Prizes should be bigger or equal than 0');
      dailyEmpty = true;
    }

    if (weekly < 0) {
      createNotification('error', 'Weekly Prizes should be bigger or equal than 0');
      weeklyEmpty = true;
    }

    if (stake === undefined || stake.length === 0) {
      createNotification('error', 'Championship Stake should not be empty');
      stakeEmpty = true;
    }

    if (logo === undefined) {
      createNotification('error', 'Championship Logo should not be empty');
      logoEmpty = true;
    }

    if (cover === undefined) {
      createNotification('error', 'Championship Cover should not be empty');
      coverEmpty = true;
    }

    if (prizes.length === 0) {
      createNotification('error', 'Prizes should not be empty');
      prizeEmpty = true;
    }

    // if (questions.length === 0) {
    //   createNotification('error', 'Questions should not be empty');
    //   questionEmpty = true;
    // }
    if (matches.length === 0) {
      createNotification('error', 'Matches should not be empty');
      matchesEmpty = true;
    }

    if (prizes.length === 1) {
      if (prizes[0].deleted == true) {
        createNotification('error', 'Prizes should not be empty');
        prizeEmpty = true;
      }
    }
    // if (questions.length === 1) {
    //   if (questions[0].deleted == true) {
    //     createNotification('error', 'Questions should not be empty');
    //     questionEmpty = true;
    //   }
    // }

    if (
      howItworksEmpty !== true &&
      allTimeEmpty !== true &&
      weeklyEmpty !== true &&
      dailyEmpty !== true &&
      nameEmpty !== true &&
      stakeEmpty !== true &&
      logoEmpty !== true &&
      coverEmpty !== true &&
      prizeEmpty !== true &&
      questionEmpty !== true &&
      matchesEmpty !== true
    ) {
      let prizeTypesCount = {
        daily: daily,
        weekly: weekly,
        allTime: allTime,
      };
      let formData = new FormData();
      if (challengeId !== null) {
        formData.append('_id', challengeId);
        formData.append('challengeId', challengeId);
      }
      formData.append('name', name);
      formData.append('stake', stake);
      formData.append('logo', logo);
      formData.append('cover', cover);
      formData.append('prizeTypesCounts', JSON.stringify(prizeTypesCount));
      formData.append('qrCodeEligible', qr);

      uuidPhotos.forEach((item, index) => {
        let fileName = Object.keys(item);
        formData.append(fileName[0], item[fileName[0]]);
      });

      formData.append('howItWorks', JSON.stringify(howItWorks));
      formData.append('questions', JSON.stringify(questions));
      formData.append('matches', JSON.stringify(matches));

      formData.append('matchesToInsert', JSON.stringify(matchesInsert));
      formData.append('matchesToRemove', JSON.stringify(matchesRemove));

      formData.append('prizes', JSON.stringify(prizes));
      if (type == 'update') {
        dispatch(patchOfficialChampionshipDetail(formData));
      } else {
        setCreated(true);
        dispatch(createOfficialChampionship(formData));
      }
    }
  };

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        icon={
          <ArrowBackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/official-championships');
              dispatch({
                type: GET_OFFICIAL_CHAMPHIONSHIPS_DETAILS,
                payload: undefined,
              });
            }}
          />
        }
        title={viewDetail !== undefined ? 'Edit Official Championship' : 'Create Official Championship'}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <CmtCardContent className={classes.cardContentRoot}>
        {langChamp !== undefined && (
          <ChampionshipDetails
            langList={langChamp || {}}
            viewDetail={viewDetail}
            sendDaily={getDaily}
            sendWeekly={getWeekly}
            sendAllTime={getAllTime}
            sendQr={getQr}
            sendName={getName}
            sendStake={getStake}
            sendLogo={getLogo}
            sendCover={getCover}
            getHowItWorks={getHowItWorks}
          />
        )}
        <Box>
          <Prizes
            langList={langChamp || {}}
            viewDetail={viewDetail}
            httpImages={viewDetail !== undefined}
            getPrizes={getPrizes}
          />
        </Box>
        <Box>
          <Questions langList={langChamp || {}} viewDetail={viewDetail} getQuestions={getQuestions} />
        </Box>
        <Box>
          {matchesList !== undefined && (
            <Matches viewDetail={viewDetail} createChampionship={create} matches={matchesList} getMatches={getMatches} />
          )}
        </Box>
        <NotificationContainer />
      </CmtCardContent>
    </CmtCard>
  );
};

export default OfficialChampionship;
