import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import BarPage from './Pages/BarPage';
import MatchApp from './Pages/MatchApp';
import MatchDetailApp from './Pages/MatchDetailApp';
import BarApp from './Pages/BarApp';
import TeamsApp from './Pages/TeamsApp';
import Languages from './Pages/Languages';
import Rules from './Pages/Rules';
import CompetitionApp from './Pages/CompetitionApp';
import CompetitionDetailApp from './Pages/CompetitionDetailApp';
import OfficialChampionshipList from './Pages/OfficialChampionshipList';
import OfficialChampionship from './Pages/OfficialChampionship';
import ScanQrCode from './Pages/ScanQrCode';
import ChangePassword from './Pages/ChangePassword';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/edit-bar'} />;
  } else if (authUser && location.pathname === '/login') {
    return <Redirect to={'/edit-bar'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute path="/official-championships" component={OfficialChampionshipList} />
        <RestrictedRoute path="/official-championship/:id" component={OfficialChampionship} />
        <RestrictedRoute path="/official-championship/new" component={OfficialChampionship} />
        {/*<RestrictedRoute path="/competitions/:id" component={CompetitionDetailApp} />*/}
        {/*<RestrictedRoute path="/competitions" component={CompetitionApp} />*/}
        {/*<RestrictedRoute path="/match/:id" component={MatchDetailApp} />*/}
        <RestrictedRoute path="/matches" component={MatchApp} />
        <RestrictedRoute path="/scan-qr-code" component={ScanQrCode} />
        {/*<RestrictedRoute path="/bars" component={BarApp} />*/}
        {/*<RestrictedRoute path="/teams" component={TeamsApp} />*/}
        {/*<RestrictedRoute path="/rules" component={Rules} />*/}
        {/*<RestrictedRoute path="/translations" component={Languages} />*/}
        <RestrictedRoute path="/edit-bar" component={BarPage} />
        <RestrictedRoute path="/change-password" component={ChangePassword} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
