import {
  SYNC_COMPETITIONS_DATA,
  GET_COMPETITIONS_DATA,
  FETCH_COMPETITIONS_DATA,
  GET_COMPETITIONS_TEAMS_DATA,
  SET_COMPETITIONS_TEAMS_LOGO,
  GET_COMPETITIONS_DETAILS,
  SET_COMPETITIONS_TEAMS_TOP,
  SET_COMPETITIONS_BROADCAST,
  FETCH_COMPETITIONS_SEASON,
  TOP_COMPETITIONS_DATA,
  GET_TOP_COMPETITIONS,
  FETCH_COMPETITIONS_DETAILS,
} from '../../constants/ActionTypes';

// ------------------------------------
// Action Handlers
// ------------------------------------

const INIT_STATE = {
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    selectedFolder: 'accepted',
    selectedLabel: '',
    searchText: '',
  },
  languageList: [],
  languages: [],
  languagePlatforms: [],
  currentLanguage: null,
  totalBars: null,
  counter: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPETITIONS_DATA: {
      return {
        ...state,
        competitionsList: action.payload.data,
      };
    }

    case GET_COMPETITIONS_DETAILS: {
      return {
        ...state,
        competitionDetail: action.payload.data,
      };
    }

    case SYNC_COMPETITIONS_DATA: {
      return {
        ...state,
        syncCompetition: action.payload.data,
      };
    }

    case FETCH_COMPETITIONS_DATA: {
      return {
        ...state,
        fetchCompetition: action.payload.data,
      };
    }

    case GET_COMPETITIONS_TEAMS_DATA: {
      return {
        ...state,
        teamsList: action.payload.data,
      };
    }

    case SET_COMPETITIONS_TEAMS_LOGO: {
      return {
        ...state,
        newLogo: action.payload.data,
      };
    }

    case SET_COMPETITIONS_TEAMS_TOP: {
      return {
        ...state,
        topTeam: action.payload.data,
      };
    }

    case SET_COMPETITIONS_BROADCAST: {
      return {
        ...state,
        broadcastCompetition: action.payload.data,
      };
    }

    case FETCH_COMPETITIONS_SEASON: {
      return {
        ...state,
        competitionSeason: action.payload.data,
      };
    }

    case FETCH_COMPETITIONS_DETAILS: {
      return {
        ...state,
        competitionsDetails: action.payload.data,
      };
    }

    case TOP_COMPETITIONS_DATA: {
      return {
        ...state,
        topCompetition: action.payload.data,
      };
    }
    case GET_TOP_COMPETITIONS: {
      return {
        ...state,
        competitionsList: action.payload.data,
      };
    }

    default:
      return state;
  }
};
