import mock from '../mockConfig';
import { OfficialChampionshipList, officialChampionships, users } from '../../@fake-db/officialChampionship';
import { idGenerator } from '../../utils/commonHelper';

let lists = OfficialChampionshipList;
let officialChampionshipsList = officialChampionships;
const currentUser = users[0];

mock.onGet('/official-championships').reply(req => {
  const { filterType, officialChampionshipList, search } = req.params.filterData;

  if (search) {
    return [200, officialChampionshipsList.filter(item => item.title.toLowerCase().includes(search.toLowerCase()))];
  }
  if (officialChampionshipList)
    return [200, officialChampionshipsList.filter(item => item.officialChampionshipList === officialChampionshipList)];

  if (filterType === 1) {
    return [200, officialChampionshipsList.filter(item => item.assigned && item.assigned.id === currentUser.id)];
  }
  if (filterType === 2) {
    return [200, officialChampionshipsList];
  }
  if (filterType === 3) {
    return [200, officialChampionshipsList.filter(item => item.isStared)];
  }
  return [200, officialChampionshipsList];
});

mock.onPost('/official-championships').reply(request => {
  const { officialChampionship } = JSON.parse(request.data);
  const newOfficialChampionship = {
    ...officialChampionship,
    id: idGenerator(),
  };
  officialChampionshipsList.push(newOfficialChampionship);
  return [200, newOfficialChampionship];
});

mock.onPut('/official-championships').reply(request => {
  const { officialChampionship } = JSON.parse(request.data);
  officialChampionshipsList = officialChampionshipsList.map(item =>
    item.id === officialChampionship.id ? officialChampionship : item,
  );
  return [200];
});

mock.onDelete('/official-championships').reply(request => {
  const { officialChampionship } = request.params;
  officialChampionshipsList = officialChampionshipsList.filter(item => item.id !== officialChampionship.id);
  return [200];
});

mock.onGet('/official-championship-list').reply(200, {
  officialChampionshipList: lists,
});

mock.onPost('/official-championship-list').reply(request => {
  const { officialChampionshipList } = JSON.parse(request.data);
  let newOfficialChampionshipList = { ...officialChampionshipList, id: idGenerator() };
  lists.push(newOfficialChampionshipList);
  return [200, newOfficialChampionshipList];
});

mock.onPut('/official-championship-list').reply(request => {
  const { officialChampionshipList } = JSON.parse(request.data);
  lists = lists.map(item => (item.id === officialChampionshipList.id ? officialChampionshipList : item));
  return [200];
});

mock.onDelete('/official-championship-list').reply(request => {
  const { officialChampionshipList } = request.params;
  lists = lists.filter(item => item.id !== officialChampionshipList.id);
  return [200];
});

mock.onGet('/official-championships/counter').reply(config => {
  const counter = { lists: {} };

  counter.all = officialChampionshipsList.length;
  counter.important = officialChampionshipsList.filter(officialChampionship => officialChampionship.isStared).length;
  counter.myOfficialChampionships = officialChampionshipsList.filter(
    officialChampionship => officialChampionship.assigned && officialChampionship.assigned.id === currentUser.id,
  ).length;

  lists.map(item => {
    counter.lists[item.id] = officialChampionshipsList.filter(
      officialChampionship => officialChampionship.officialChampionshipList === item.id,
    ).length;
    return null;
  });

  return [200, counter];
});
