import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CmtAvatar from '../../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import useStyles from '../MatchCell.style';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MatchCellOptions from './MatchCellOptions';
import { useHistory } from 'react-router-dom';
import { setPopularMatch } from '../../../../../redux/actions/MatchApp';
import Switch from '@material-ui/core/Switch';

import Moment from 'moment';

const MatchCell = ({ filterType, match, checkedMatches, handleCellCheckBox, onShowMatchDetail, onClickEditMatch }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    _id,
    broadcasted,
    popular,
    status,
    headline,
    goals,
    homeTeamEmblemUrl,
    awayTeamEmblemUrl,
    scheduledDate,
    competitionName,
    matchType,
    profilePic,
  } = match;
  const [newPopular, setNewPopular] = useState(broadcasted);
  const onClickPopularMatch = e => {
    setNewPopular(!newPopular);
    dispatch(setPopularMatch(_id, !newPopular));
  };

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                mr={4}
                style={{
                  width: '30px',
                  overflow: 'hidden',
                  marginRight: '0',
                  borderRight: '1px solid #fff',
                }}>
                <CmtAvatar size={60} src={homeTeamEmblemUrl} alt={headline} />
              </Box>
              <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
                <CmtAvatar size={60} style={{ transform: 'translateX(-30px)' }} src={awayTeamEmblemUrl} alt={headline} />
              </Box>
            </Box>

            <Box>
              <Typography className={classes.titleRoot} component="div" variant="h4">
                {headline}{' '}
                <Typography style={{ display: 'inline-block' }} className={classes.subTitleRoot}>
                  ({status})
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      </TableCell>
      {filterType == 'past' ? (
        <TableCell className={classes.tableCellRoot}>
          {goals ? (
            <span>
              {goals.fullTimeHomeGoals} - {goals.fullTimeAwayGoals}
            </span>
          ) : (
            <span>0 - 0</span>
          )}
        </TableCell>
      ) : null}
      {filterType == 'live' ? (
        <TableCell className={classes.tableCellRoot}>
          {goals ? (
            <span>
              {goals.liveHomeGoals} - {goals.liveAwayGoals}
            </span>
          ) : (
            <span>0 - 0</span>
          )}
        </TableCell>
      ) : null}
      <TableCell className={classes.tableCellRoot}>{Moment(scheduledDate).format('H:mm - D MMM yyyy')}</TableCell>
      <TableCell className={classes.tableCellRoot}>{competitionName}</TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Switch checked={newPopular} onChange={onClickPopularMatch} name="isPublished" color="primary" />
      </TableCell>
      <TableCell className={clsx(classes.tableCellRoot, classes.tableCellAction)}>
        {/*<MatchCellOptions*/}
        {/*  filterType={filterType}*/}
        {/*  popularType={newPopular}*/}
        {/*  match={match}*/}
        {/*  onClickTopMatch={onClickPopularMatch}*/}
        {/*  onClickEditMatch={() => {*/}
        {/*    history.push('/match/' + _id);*/}
        {/*  }}*/}
        {/*/>*/}
      </TableCell>
    </TableRow>
  );
};

export default MatchCell;

MatchCell.prototype = {
  match: PropTypes.object.isRequired,
  checkedMatches: PropTypes.array,
  handleCellCheckBox: PropTypes.func,
  onShowMatchDetail: PropTypes.func,
  onClickEditMatch: PropTypes.func,
};

MatchCell.defaultProps = {
  checkedMatches: [],
};
