import { CHANGE_PASSWORD } from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const changePasswordAdmin = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/users/change-password', body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchError('Password Changed'));
        dispatch({ type: CHANGE_PASSWORD, payload: data });
        localStorage.removeItem('token');
        localStorage.removeItem('login');
        window.location.href = '/login';
      })
      .catch(error => {
        if (error.response.data.message == 'auth.WRONG_CREDENTIALS') {
          dispatch(fetchError('Invalid old password'));
        } else {
          if (error.response.status != 401) {
            dispatch(fetchError('Something went wrong'));
          }
        }
      });
  };
};
