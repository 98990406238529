import moment from 'moment';

export const OfficialChampionshipStatus = Object.freeze({
  COMPLETED: 1,
  IN_PROGRESS: 2,
  NOT_STARTED: 3,
});

export const prizeCat = [
  {
    id: 1,
    name: 'All Time',
  },
  {
    id: 2,
    name: 'Weekly',
  },
  {
    id: 3,
    name: 'Daily',
  },
]

export const users = [
  {
    id: 1,
    email: 'robert@example.com',
    name: 'Germany - Tunis',
    profilePic: 'https://via.placeholder.com/150x150',
  },
  {
    id: 2,
    name: 'France - England ',
    profilePic: 'https://via.placeholder.com/150x150',
    email: 'mukesh@example.com',
  },
  {
    id: 3,
    email: 'sonu@example.com',
    name: 'USA - Brazil',
    profilePic: 'https://via.placeholder.com/150x150',
  },
  {
    id: 4,
    name: 'Argentina - Moldova',
    profilePic: 'https://via.placeholder.com/150x150',
    email: 'dennis@example.com',
  },
  {
    id: 5,
    name: 'Feroe Islands - Iceland',
    profilePic: 'https://via.placeholder.com/150x150',
    email: 'atul@example.com',
  },
  {
    id: 6,
    name: 'Denmark - Spain',
    profilePic: 'https://via.placeholder.com/150x150',
    email: 'murli@example.com',
  },
  {
    id: 7,
    name: 'Romania - Hungary',
    profilePic: 'https://via.placeholder.com/150x150',
    email: 'tanmay@example.com',
  },
];
export const officialChampionships = [
  {
    id: 1,
    title: 'Uefa Yepp Championship',
    officialChampionshipList: 1,
    assigned: {
      ...users[0],
    },
    attachments: [
      {
        id: 1,
        name: 'Regulations.pdf',
        path: 'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf',
        metaData: {},
      },
    ],
    matches: [
      {
        id: 1457690403,
        name: 'Santiago Wanderers vs Colo-Colo',
        profilePic: 'https://via.placeholder.com/150x150',
        country: 'England',
        dates: [{ date: '27.03.2012 10:30', label: 'home' }],
        matchType: 'semi finals',
        starred: false,
        frequent: true,
        competition: 'France Cup',
        labels: [],
        folder: 'future',
      },
      {
        id: 1457890404,
        name: 'Universidad Católica vs Huachipato',
        profilePic: 'https://via.placeholder.com/150x150',
        country: 'England',
        dates: [{ date: '27.03.2012 10:30', label: 'home' }],
        matchType: 'semi finals',
        starred: true,
        frequent: false,
        competition: 'Serie A',
        labels: [],
        folder: 'future',
      },
    ],
    isStared: true,
    isPublished: true,
    comments: [
      {
        id: 1,
        user: users[0],
        message: 'Hi @Mukesh k. Do I have any anyting left in this officialChampionship?',
        createdAt: '',
      },
      {
        id: 2,
        user: users[1],
        message: 'Hi @John Smith Everything Done! Fantastic job ',
        createdAt: '',
      },
      {
        id: 3,
        user: users[0],
        message: 'Hi @Mukesh Great ',
        createdAt: '',
      },
    ],
    prizes: [
      {
        id: 11,
        title: 'First Prize',
        status: OfficialChampionshipStatus.COMPLETED,
        createdAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
        updatedAt: '',
      },
      {
        id: 12,
        title: 'Second Prize',
        status: OfficialChampionshipStatus.NOT_STARTED,
        createdAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
        updatedAt: '',
      },
      {
        id: 13,
        title: 'Elementar prize',
        status: OfficialChampionshipStatus.NOT_STARTED,
        createdAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
        updatedAt: '',
      },
      {
        id: 14,
        title: 'Nobel Prize',
        status: OfficialChampionshipStatus.COMPLETED,
        createdAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
        updatedAt: '',
      },
      {
        id: 15,
        title: 'Compensatory prize',
        status: OfficialChampionshipStatus.NOT_STARTED,
        createdAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
        updatedAt: '',
      },
    ],
    questions: [
      {
        id: 1,
        title: 'Question Nr 1',
        answer: [
          {
            id: 1,
            answer_title: 'Answer 1 question nr 1'
          },
          {
            id: 2,
            answer_title: 'Answer 2 question nr 1'
          }
        ]
      },
      {
        id: 2,
        title: 'Question Nr 2',
        answer: [
          {
            id: 1,
            answer_title: 'Answer 1 question nr 2'
          },
          {
            id: 2,
            answer_title: 'Answer 2 question nr 2'
          }
        ]
      }
    ],
    dueDate: moment().subtract(2, 'days'),
  },
  {
    id: 2,
    title: 'World Cup Championship',
    officialChampionshipList: 1,
    assigned: {
      ...users[1],
    },
    attachments: [
      {
        id: 1,
        name: 'Regulations.pdf',
        path: 'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf',
        metaData: {},
      },
    ],
    matches: [
      {
        id: 1457690403,
        name: 'Santiago Wanderers vs Colo-Colo',
        profilePic: 'https://via.placeholder.com/150x150',
        country: 'England',
        dates: [{ date: '27.03.2012 10:30', label: 'home' }],
        matchType: 'semi finals',
        starred: false,
        frequent: true,
        competition: 'France Cup',
        labels: [],
        folder: 'future',
      },
      {
        id: 1457890404,
        name: 'Universidad Católica vs Huachipato',
        profilePic: 'https://via.placeholder.com/150x150',
        country: 'England',
        dates: [{ date: '27.03.2012 10:30', label: 'home' }],
        matchType: 'semi finals',
        starred: true,
        frequent: false,
        competition: 'Serie A',
        labels: [],
        folder: 'future',
      },
    ],
    isStared: false,
    isPublished: false,
    comments: [
      {
        id: 1,
        user: users[0],
        message: 'Hi @Mukesh k. Do I have any anyting left in this officialChampionship?',
        createdAt: '',
      },
      {
        id: 2,
        user: users[1],
        message: 'Hi @John Smith Everything Done! Fantastic job ',
        createdAt: '',
      },
    ],
    prizes: [
      {
        id: 11,
        title: 'Define color pallate',
        status: OfficialChampionshipStatus.COMPLETED,
        createdAt: '',
        updatedAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
      },
      {
        id: 12,
        title: 'Create style guide docs.',
        status: OfficialChampionshipStatus.NOT_STARTED,
        createdAt: '',
        updatedAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
      },
      {
        id: 13,
        title: 'React Master project.',
        status: OfficialChampionshipStatus.NOT_STARTED,
        createdAt: '',
        updatedAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
      },
    ],
    questions: [
      {
        id: 1,
        title: 'Question Nr 1',
        answer: [
          {
            id: 1,
            answer_title: 'Answer 1 question nr 1'
          },
          {
            id: 2,
            answer_title: 'Answer 2 question nr 1'
          }
        ]
      },
      {
        id: 2,
        title: 'Question Nr 2',
        answer: [
          {
            id: 1,
            answer_title: 'Answer 1 question nr 2'
          },
          {
            id: 2,
            answer_title: 'Answer 2 question nr 2'
          }
        ]
      }
    ],
    dueDate: moment().subtract(1, 'days'),
  },
  {
    id: 3,
    title: 'Africa Official Yepp',
    officialChampionshipList: 1,
    assigned: {
      ...users[0],
    },
    attachments: [
      {
        id: 1,
        name: 'Regulations.pdf',
        path: 'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf',
        metaData: {},
      },
    ],
    matches: [
      {
        id: 1457690403,
        name: 'Santiago Wanderers vs Colo-Colo',
        profilePic: 'https://via.placeholder.com/150x150',
        country: 'England',
        dates: [{ date: '27.03.2012 10:30', label: 'home' }],
        matchType: 'semi finals',
        starred: false,
        frequent: true,
        competition: 'France Cup',
        labels: [],
        folder: 'future',
      },
      {
        id: 1457890404,
        name: 'Universidad Católica vs Huachipato',
        profilePic: 'https://via.placeholder.com/150x150',
        country: 'England',
        dates: [{ date: '27.03.2012 10:30', label: 'home' }],
        matchType: 'semi finals',
        starred: true,
        frequent: false,
        competition: 'Serie A',
        labels: [],
        folder: 'future',
      },
    ],
    isStared: true,
    isPublished: true,
    comments: [
      {
        id: 1,
        user: users[0],
        message: 'Hi @Mukesh k. Do I have any anyting left in this officialChampionship?',
        createdAt: '',
      },
      {
        id: 2,
        user: users[1],
        message: 'Hi @John Smith Everything Done! Fantastic job ',
        createdAt: '',
      },
    ],
    prizes: [
      {
        id: 11,
        title: 'Define color pallate',
        status: OfficialChampionshipStatus.COMPLETED,
        createdAt: '',
        updatedAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
      },
      {
        id: 12,
        title: 'Create style guide docs.',
        status: OfficialChampionshipStatus.NOT_STARTED,
        createdAt: '',
        updatedAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
      },
    ],
    questions: [
      {
        id: 1,
        title: 'Question Nr 1',
        answer: [
          {
            id: 1,
            answer_title: 'Answer 1 question nr 1'
          },
          {
            id: 2,
            answer_title: 'Answer 2 question nr 1'
          }
        ]
      },
      {
        id: 2,
        title: 'Question Nr 2',
        answer: [
          {
            id: 1,
            answer_title: 'Answer 1 question nr 2'
          },
          {
            id: 2,
            answer_title: 'Answer 2 question nr 2'
          }
        ]
      }
    ],
    dueDate: moment(),
  },
  {
    id: 4,
    title: 'Due Yepp Nomad',
    officialChampionshipList: 2,
    assigned: {
      ...users[1],
    },
    matches: [
      {
        id: 1457690403,
        name: 'Santiago Wanderers vs Colo-Colo',
        profilePic: 'https://via.placeholder.com/150x150',
        country: 'England',
        dates: [{ date: '27.03.2012 10:30', label: 'home' }],
        matchType: 'semi finals',
        starred: false,
        frequent: true,
        competition: 'France Cup',
        labels: [],
        folder: 'future',
      },
      {
        id: 1457890404,
        name: 'Universidad Católica vs Huachipato',
        profilePic: 'https://via.placeholder.com/150x150',
        country: 'England',
        dates: [{ date: '27.03.2012 10:30', label: 'home' }],
        matchType: 'semi finals',
        starred: true,
        frequent: false,
        competition: 'Serie A',
        labels: [],
        folder: 'future',
      },
    ],
    isStared: false,
    isPublished: false,
    comments: [
      {
        id: 1,
        user: users[0],
        message: 'Hi @Mukesh k. Do I have any anyting left in this officialChampionship?',
        createdAt: '',
      },
      {
        id: 2,
        user: users[1],
        message: 'Hi @John Smith Everything Done! Fantastic job ',
        createdAt: '',
      },
    ],
    attachments: [
      {
        id: 1,
        name: 'Regulations.pdf',
        path: 'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf',
        metaData: {},
      },
    ],
    prizes: [
      {
        id: 11,
        title: 'Define color pallate',
        status: OfficialChampionshipStatus.COMPLETED,
        createdAt: '',
        updatedAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
      },
      {
        id: 12,
        title: 'Create style guide docs.',
        status: OfficialChampionshipStatus.NOT_STARTED,
        createdAt: '',
        updatedAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
      },
    ],
    questions: [
      {
        id: 1,
        title: 'Question Nr 1',
        answer: [
          {
            id: 1,
            answer_title: 'Answer 1 question nr 1'
          },
          {
            id: 2,
            answer_title: 'Answer 2 question nr 1'
          }
        ]
      },
      {
        id: 2,
        title: 'Question Nr 2',
        answer: [
          {
            id: 1,
            answer_title: 'Answer 1 question nr 2'
          },
          {
            id: 2,
            answer_title: 'Answer 2 question nr 2'
          }
        ]
      }
    ],
    dueDate: moment().add(1, 'days'),
  },
  {
    id: 5,
    title: 'Elemental Championship',
    officialChampionshipList: 2,
    assigned: {
      ...users[1],
    },
    attachments: [
      {
        id: 1,
        name: 'Regulations.pdf',
        path: 'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf',
        metaData: {},
      },
    ],
    matches: [
      {
        id: 1457690403,
        name: 'Santiago Wanderers vs Colo-Colo',
        profilePic: 'https://via.placeholder.com/150x150',
        country: 'England',
        dates: [{ date: '27.03.2012 10:30', label: 'home' }],
        matchType: 'semi finals',
        starred: false,
        frequent: true,
        competition: 'France Cup',
        labels: [],
        folder: 'future',
      },
      {
        id: 1457890404,
        name: 'Universidad Católica vs Huachipato',
        profilePic: 'https://via.placeholder.com/150x150',
        country: 'England',
        dates: [{ date: '27.03.2012 10:30', label: 'home' }],
        matchType: 'semi finals',
        starred: true,
        frequent: false,
        competition: 'Serie A',
        labels: [],
        folder: 'future',
      },
    ],
    isStared: true,
    isPublished: true,
    comments: [
      {
        id: 1,
        user: users[0],
        message: 'Hi @Mukesh k. Do I have any anyting left in this officialChampionship?',
        createdAt: '',
      },
      {
        id: 2,
        user: users[1],
        message: 'Hi @John Smith Everything Done! Fantastic job ',
        createdAt: '',
      },
    ],
    prizes: [
      {
        id: 11,
        title: 'Define color pallate',
        status: OfficialChampionshipStatus.COMPLETED,
        createdAt: '',
        updatedAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
      },
      {
        id: 12,
        title: 'Create style guide docs.',
        status: OfficialChampionshipStatus.NOT_STARTED,
        createdAt: '',
        updatedAt: '',
        assigned: {
          ...users[0],
        },
        prizeCategory: {
          ...prizeCat[0],
        },
      },
    ],
    questions: [
      {
        id: 1,
        title: 'Question Nr 1',
        answer: [
          {
            id: 1,
            answer_title: 'Answer 1 question nr 1'
          },
          {
            id: 2,
            answer_title: 'Answer 2 question nr 1'
          }
        ]
      },
      {
        id: 2,
        title: 'Question Nr 2',
        answer: [
          {
            id: 1,
            answer_title: 'Answer 1 question nr 2'
          },
          {
            id: 2,
            answer_title: 'Answer 2 question nr 2'
          }
        ]
      }
    ],
    dueDate: moment().add(2, 'days'),
  },
];

export const OfficialChampionshipList = [
  {
    id: 1,
    name: 'Europe',
    color: '#FF8C00',
  },
  {
    id: 2,
    name: 'Worldwide',
    color: '#0795F4',
  },
];
