import React, {useState, useEffect, useContext} from 'react';
import ListHeader from './ListHeader';
import Table from '@material-ui/core/Table';
import { useSelector } from 'react-redux';
import TableBody from '@material-ui/core/TableBody';
import MatchCell from './MatchCell';
import CheckedListHeader from './CheckedListHeader';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import {getMatchesList} from "../../../../../redux/actions/MatchApp";
import {counter} from "../index";
import TablePagination from "@material-ui/core/TablePagination";
import PerfectScrollbar from "react-perfect-scrollbar";
import useStyles from "../../index.style";
import {getMatchContainerHeight} from "../../../../../constants/AppConstants";
import AppContext
  from "../../../../../components/contextProvider/AppContextProvider/AppContext";

const ListTableView = ({
  checkedMatches,
  handleCellCheckBox,
  handleHeaderCheckBox,
  updateCheckedMatches,
  onShowMatchDetail,
  onClickEditMatch,
  filterType,
  width,
}) => {
  const { matchesList } = useSelector(({ matchApp }) => matchApp);
  const [ matchesListTable, setMatchesListTable] = useState({});
  const { showFooter } = useContext(AppContext);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  useEffect(() => {
    setMatchesListTable(matchesList.slice(0, 50))
    setCount(matchesList.length);
    setPage(0)
  }, [matchesList]);

  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter) + 80,
  });

  const handleChangePage = (event, newPage) =>{
    setMatchesListTable(matchesList.slice(newPage*rowsPerPage, (newPage*rowsPerPage)+rowsPerPage) ||{});
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event =>{
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setMatchesListTable(matchesList.slice(page, rowsPerPage) ||{});
  };

  return (
    <React.Fragment>
      {checkedMatches.length > 0 && (
        <CheckedListHeader
          checkedMatches={checkedMatches}
          handleHeaderCheckBox={handleHeaderCheckBox}
          updateCheckedMatches={updateCheckedMatches}
        />
      )}
      <Box className="Cmt-table-responsive">
        <PerfectScrollbar className={classes.perfectScrollbarMatchCon}>
        <Table>
          {checkedMatches.length === 0 && (
            <ListHeader
              matchesList={matchesListTable}
              checkedMatches={checkedMatches}
              filterType={filterType}
              handleHeaderCheckBox={handleHeaderCheckBox}
            />
          )}
          <TableBody>
            {matchesListTable != undefined && matchesListTable.length  ? matchesListTable.map((match, index) => (
              <MatchCell
                key={index}
                match={match}
                filterType={filterType}
                checkedMatches={checkedMatches}
                handleCellCheckBox={handleCellCheckBox}
                onShowMatchDetail={onShowMatchDetail}
                onClickEditMatch={onClickEditMatch}
              />
            )) : null}

          </TableBody>
        </Table>
        </PerfectScrollbar>
      </Box>
      <TablePagination
        component="div"
        style={{order: '9999', width: '100%'}}
        count={count}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export default ListTableView;

ListTableView.prototype = {
  checkedMatches: PropTypes.array,
  handleCellCheckBox: PropTypes.func,
  handleHeaderCheckBox: PropTypes.func,
  updateCheckedMatches: PropTypes.func,
  onShowMatchDetail: PropTypes.func,
  onClickEditMatch: PropTypes.func,
};

ListTableView.defaultProps = {
  checkedMatches: [],
};
