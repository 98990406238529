import React from 'react';
import ListHeader from './ListHeader';
import Table from '@material-ui/core/Table';
import { useSelector } from 'react-redux';
import TableBody from '@material-ui/core/TableBody';
import BarCell from './BarCell';
import CheckedListHeader from './CheckedListHeader';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const ListTableView = ({
  checkedBars,
  handleCellCheckBox,
  handleHeaderCheckBox,
  updateCheckedBars,
  onShowBarDetail,
  onClickEditBar,
}) => {
  const { barsList } = useSelector(({ barApp }) => barApp);
  return (
    <React.Fragment>
      {checkedBars.length > 0 && (
        <CheckedListHeader
          checkedBars={checkedBars}
          handleHeaderCheckBox={handleHeaderCheckBox}
          updateCheckedBars={updateCheckedBars}
        />
      )}
      <Box className="Cmt-table-responsive">
        <Table>
          {checkedBars.length === 0 && (
            <ListHeader
              barsList={barsList}
              checkedBars={checkedBars}
              handleHeaderCheckBox={handleHeaderCheckBox}
            />
          )}
          <TableBody>
            {barsList.map((bar, index) => (
              <BarCell
                key={index}
                bar={bar}
                checkedBars={checkedBars}
                handleCellCheckBox={handleCellCheckBox}
                onShowBarDetail={onShowBarDetail}
                onClickEditBar={onClickEditBar}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
};

export default ListTableView;

ListTableView.prototype = {
  checkedBars: PropTypes.array,
  handleCellCheckBox: PropTypes.func,
  handleHeaderCheckBox: PropTypes.func,
  updateCheckedBars: PropTypes.func,
  onShowBarDetail: PropTypes.func,
  onClickEditBar: PropTypes.func,
};

ListTableView.defaultProps = {
  checkedBars: [],
};
