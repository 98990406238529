import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getBarDetail, editBarDetail } from '../../../redux/actions/BarPage';
import { getAllRules } from '../../../redux/actions/Rules';
import { fetchAllLanguages } from '../../../redux/actions/Languages';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button';
import { fetchError } from '../../../redux/actions';

const BarPage = () => {
  const { barDetail } = useSelector(({ barPage }) => barPage);
  const [barDetails, setBarDetails] = useState();
  const [barName, setBarName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [facebook, setFacebook] = useState('');

  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');

  const [monday, setMonday] = useState('');
  const [tuesday, setTuesday] = useState('');
  const [wednesday, setWednesday] = useState('');
  const [thursday, setThursday] = useState('');
  const [friday, setFriday] = useState('');
  const [saturday, setSaturday] = useState('');
  const [sunday, setSunday] = useState('');

  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    inBuildAppCard: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      margin: '15px',
      padding: '25px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
      position: 'relative',
      overflow: 'hidden',
    },
    RowTextfield: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    TextField: {
      maxWidth: 'calc(33.3% - 20px)',
      width: '100%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  }));
  const classes = useStyles();

  const saveBar = () => {
    if (
      city.length == 0 ||
      country.length == 0 ||
      state.length == 0 ||
      number.length == 0 ||
      postalCode.length == 0 ||
      street.length == 0 ||
      monday.length == 0 ||
      tuesday.length == 0 ||
      wednesday.length == 0 ||
      thursday.length == 0 ||
      friday.length == 0 ||
      saturday.length == 0 ||
      sunday.length == 0 ||
      barName.length == 0 ||
      email.length == 0 ||
      phone.length == 0 ||
      website.length == 0 ||
      facebook.length == 0
    ) {
      dispatch(fetchError('All fields are required'));
      return;
    }
    var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    if (!facebook.match(regex)) {
      dispatch(fetchError('Facebook needs to be an url'));
      return;
    }
    if (!website.match(regex)) {
      dispatch(fetchError('Website needs to be an url'));
      return;
    }
    let data = {
      address: {
        city: city,
        country: country,
        state: state,
        number: number,
        postalCode: postalCode,
        street: street,
      },
      barId: JSON.parse(localStorage.getItem('login')).barId,
      barProgram: {
        monday: monday,
        tuesday: tuesday,
        wednesday: wednesday,
        thursday: thursday,
        friday: friday,
        saturday: saturday,
        sunday: sunday,
      },
      email: email,
      facebookUrl: facebook,
      name: barName,
      telephoneNumber: phone,
      website: website,
    };
    setIsEditing(!isEditing);
    dispatch(editBarDetail(data));
  };

  useEffect(() => {
    dispatch(getBarDetail());
  }, [dispatch]);
  useEffect(() => {
    setBarDetails(barDetail);
    if (barDetail !== undefined) {
      setBarName(barDetail.name);
      setEmail(barDetail.bar_user[0].email);
      setPhone(barDetail.telephoneNumber);
      setWebsite(barDetail.website);
      setFacebook(barDetail.facebookUrl);
      if (barDetail.address != null) {
        setCountry(barDetail.address.country);
        setState(barDetail.address.state);
        setCity(barDetail.address.city);
        setStreet(barDetail.address.street);
        setNumber(barDetail.address.number);
        setPostalCode(barDetail.address.postalCode);
      }
      if (barDetail.barProgram !== undefined) {
        setMonday(barDetail.barProgram.monday);
        setTuesday(barDetail.barProgram.tuesday);
        setWednesday(barDetail.barProgram.wednesday);
        setThursday(barDetail.barProgram.thursday);
        setFriday(barDetail.barProgram.friday);
        setSaturday(barDetail.barProgram.saturday);
        setSunday(barDetail.barProgram.sunday);
      }
    }
  }, [barDetail]);
  return (
    <Box component="div" className={classes.inBuildAppCard}>
      <Box style={{ margin: '0 0 10px 10px' }}>
        <h1>Bar details</h1>
      </Box>
      <Box className={classes.RowTextfield}>
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setBarName(e.target.value);
          }}
          value={barName}
          label="Bar Name"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setEmail(e.target.value);
          }}
          value={email}
          label="Bar Email"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setPhone(e.target.value);
          }}
          value={phone}
          label="Bar Phone"
          variant="outlined"
          disabled={!isEditing}
        />
      </Box>
      <Box className={classes.RowTextfield}>
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setWebsite(e.target.value);
          }}
          value={website}
          label="Website"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setFacebook(e.target.value);
          }}
          value={facebook}
          label="Facebook"
          variant="outlined"
          disabled={!isEditing}
        />
      </Box>
      <Box style={{ margin: '25px 0 0 10px' }}>
        <h3>Address:</h3>
      </Box>
      <Box className={classes.RowTextfield}>
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setCountry(e.target.value);
          }}
          value={country}
          label="Country"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setState(e.target.value);
          }}
          value={state}
          label="State"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setCity(e.target.value);
          }}
          value={city}
          label="City"
          variant="outlined"
          disabled={!isEditing}
        />
      </Box>
      <Box className={classes.RowTextfield}>
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setStreet(e.target.value);
          }}
          value={street}
          label="Street"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setNumber(e.target.value);
          }}
          value={number}
          label="Number"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setPostalCode(e.target.value);
          }}
          value={postalCode}
          label="Postal Code"
          variant="outlined"
          disabled={!isEditing}
        />
      </Box>
      <Box style={{ margin: '25px 0 0 10px' }}>
        <h3>Schedule:</h3>
      </Box>
      <Box className={classes.RowTextfield}>
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setMonday(e.target.value);
          }}
          value={monday}
          label="Monday"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setTuesday(e.target.value);
          }}
          value={tuesday}
          label="Tuesday"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setWednesday(e.target.value);
          }}
          value={wednesday}
          label="Wednesday"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setThursday(e.target.value);
          }}
          value={thursday}
          label="Thursday"
          variant="outlined"
          disabled={!isEditing}
        />
      </Box>
      <Box className={classes.RowTextfield}>
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setFriday(e.target.value);
          }}
          value={friday}
          label="Friday"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setSaturday(e.target.value);
          }}
          value={saturday}
          label="Saturday"
          variant="outlined"
          disabled={!isEditing}
        />
        <TextField
          id="outlined-basic"
          className={classes.TextField}
          onChange={e => {
            setSunday(e.target.value);
          }}
          value={sunday}
          label="Sunday"
          variant="outlined"
          disabled={!isEditing}
        />
      </Box>
      <Box style={{ margin: '20px 0 0 10px' }}>
        {isEditing == false && (
          <Button variant="contained" color="primary" component="span" onClick={e => setIsEditing(!isEditing)}>
            Edit Bar
          </Button>
        )}
        {isEditing == true && (
          <Button variant="contained" color="primary" component="span" onClick={e => saveBar()}>
            Update Bar
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BarPage;
