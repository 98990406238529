import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../components/Common/formElements/AppTextInput';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../components/Common/formElements/AppSelectBox';
import { requiredMessage } from '../../../../constants/ErrorMessages';
import { createMatch, onUpdateMatch } from '../../../../redux/actions/MatchApp';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.dark,
    },
  },
}));

function NumberFormatCustom(props) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      onValueChange={values => {
        onChange(values.formattedValue);
      }}
      format="##.##.####"
    />
  );
}

const labels = [
  { title: 'Start Date', slug: 'start_date' },
  { title: 'End Date', slug: 'end_date' },
];

const splitName = Match => {
  if (Match) {
    const [fName, mName, lName] = Match.name.split(' ');
    return [fName, lName ? mName + ' ' + lName : mName];
  }

  return ['', ''];
};

const CreateMatch = ({ open, handleDialog }) => {
  const { currentMatch } = useSelector(({ matchApp }) => matchApp);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [fName, lName] = splitName(currentMatch);
  const [profilePic, setprofilePic] = useState(currentMatch ? currentMatch.profilePic : '');
  const [fname, setFname] = useState(fName);
  const [lname, setLname] = useState(lName);
  const [country, setCountry] = useState(currentMatch ? currentMatch.country : '');
  const [competition, setCompetition] = useState(currentMatch ? currentMatch.competition : '');
  const [matchType, setMatchType] = useState(currentMatch ? currentMatch.matchType : '');
  const [dates, setDates] = useState(currentMatch ? currentMatch.dates : [{ date: '', label: 'start_date' }]);

  const [fnameError, setFnameError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [dateError, setDateError] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setprofilePic(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const onAddDateRow = () => {
    setDates(dates.concat({ date: '', label: 'start_date' }));
  };

  const onRemoveDateRow = index => {
    const updatedList = [...dates];
    updatedList.splice(index, 1);
    setDates(updatedList);
  };

  const onAddDateNo = (number, index) => {
    const updatedList = [...dates];
    updatedList[index].date = number;
    setDates(updatedList);
    setDateError('');
  };

  const onSelectLabel = (value, index) => {
    const updatedList = [...dates];
    updatedList[index].label = value;
    setDates(updatedList);
  };

  const isDatesMultiple = dates.length > 1;

  const checkValidations = () => {
    const datesData = dates.filter(item => item.date.trim());
    if (!fname) {
      setFnameError(requiredMessage);
    } else if (!country) {
      setCountryError(requiredMessage);
    } else if (datesData.length === 0) {
      setDateError(requiredMessage);
    } else {
      handleSubmit(datesData);
    }
  };

  const handleSubmit = dates => {
    const Match = {
      profilePic,
      name: `${fname} ${lname}`,
      country,
      dates: dates,
      competition,
      matchType,
      folder: 'future'
    };
    if (currentMatch) {
      dispatch(onUpdateMatch({ ...currentMatch, ...Match }));
    } else {
      dispatch(createMatch(Match));
    }
    handleDialog();
  };

  return (
    <Dialog open={open} onClose={handleDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentMatch ? 'Edit Match Details' : 'Create New Match'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <Box {...getRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className="pointer">
            <input {...getInputProps()} />
            <CmtAvatar size={70} src={profilePic} />
          </Box>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Home team"
                value={fname}
                onChange={e => {
                  setFname(e.target.value);
                  setFnameError('');
                }}
                helperText={fnameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Away team"
                value={lname}
                onChange={e => setLname(e.target.value)}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant="outlined"
            label="Country"
            value={country}
            onChange={e => {
              setCountry(e.target.value);
              setCountryError('');
            }}
            helperText={countryError}
          />
        </Box>
        <CmtList
          data={dates}
          renderRow={(item, index) => (
            <GridContainer style={{ marginBottom: 12 }} key={index}>
              <Grid item xs={12} sm={isDatesMultiple ? 6 : 8}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Date"
                  value={item.date}
                  onChange={number => onAddDateNo(number, index)}
                  helperText={dateError}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={isDatesMultiple ? 9 : 12} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={labels}
                  label="Label"
                  valueKey="slug"
                  variant="outlined"
                  labelKey="title"
                  value={item.label}
                  onChange={e => onSelectLabel(e.target.value, index)}
                />
              </Grid>
              {isDatesMultiple && (
                <Grid item xs={3} sm={2}>
                  <IconButton onClick={() => onRemoveDateRow(index)}>
                    <CancelIcon />
                  </IconButton>
                </Grid>
              )}
            </GridContainer>
          )}
        />
        <Box
          mb={{ xs: 6, md: 5 }}
          display="flex"
          alignItems="center"
          onClick={onAddDateRow}
          className="pointer"
          color="primary.main">
          <AddCircleOutlineIcon />
          <Box ml={2}>Add More</Box>
        </Box>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Competition name"
              value={competition}
              onChange={e => setCompetition(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Match type"
              value={matchType}
              onChange={e => setMatchType(e.target.value)}
            />
          </Grid>
        </GridContainer>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={handleDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={checkValidations}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateMatch;

CreateMatch.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedMatch: PropTypes.object,
};

CreateMatch.defaultProps = {
  selectedMatch: null,
};
