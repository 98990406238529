export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'login_google_user_success';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'login_facebook_user_success';
export const SIGNIN_TWITTER_USER_SUCCESS = 'login_twitter_user_success';
export const SIGNIN_GITHUB_USER_SUCCESS = 'login_github_user_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'login_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const UPDATE_AUTH_BAR = 'UPDATE_AUTH_BAR';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';
export const ADD_LABEL = 'add_label';
export const GET_LABELS_LIST = 'get_labels_list';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const SET_FILTER_TYPE = 'set_filter_type';

// Matches
export const GET_MATCHES_LIST = 'get_matches_list';
export const GET_COMPETITIONS_ACTIVE = 'GET_COMPETITIONS_ACTIVE';
export const SET_CURRENT_MATCH = 'set_current_match';
export const CREATE_MATCH = 'create_match';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_MATCH = 'delete_match';
export const UPDATE_MATCH_LABEL = 'update_match_label';
export const UPDATE_MATCH = 'update_match';
export const GET_MATCH_COUNTS = 'get_match_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';
export const PATCH_MATCH_PLAYER = 'PATCH_MATCH_PLAYER';
export const SET_MATCH_BROADCAST = 'SET_MATCH_BROADCAST';

// BARS
export const GET_BARS_LIST = 'get_bars_list';
export const SET_CURRENT_BAR = 'set_current_bar';
export const CREATE_BAR = 'create_bar';
export const UPDATE_BAR_STARRED_STATUS = 'update_bar_starred_status';
export const DELETE_BAR = 'delete_bar';
export const UPDATE_BAR_LABEL = 'update_bar_label';
export const UPDATE_BAR = 'update_bar';
export const GET_BAR_COUNTS = 'get_bar_counts';

// Official Championship
export const SET_OFFICIAL_CHAMPHIONSHIP_CURRENT_USER = 'set_official_championship_current_user';
export const SET_OFFICIAL_CHAMPHIONSHIPS_DATA = 'set_official_championships_data';
export const SET_OFFICIAL_CHAMPHIONSHIP_LIST_DATA = 'set_official_championship_list_data';
export const ADD_OFFICIAL_CHAMPHIONSHIP = 'add_official_championship';
export const DELETE_OFFICIAL_CHAMPHIONSHIP = 'delete_official_championship';
export const UPDATE_OFFICIAL_CHAMPHIONSHIP = 'update_official_championship';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_OFFICIAL_CHAMPHIONSHIP_LIST = 'add_official_championship_list';
export const UPDATE_OFFICIAL_CHAMPHIONSHIP_LIST = 'update_official_championship_list';
export const DELETE_OFFICIAL_CHAMPHIONSHIP_LIST = 'delete_official_championship_list';
export const SET_OFFICIAL_CHAMPHIONSHIP_DETAIL = 'set_official_championship_detail';
export const SEND_MESSAGE = 'send_message';
export const GET_OFFICIAL_CHAMPHIONSHIPS_COUNTS = 'get_official_championships_counts';

//Languages
export const FETCH_ALL_LANGUAGES_INVOKED = 'FETCH_ALL_LANGUAGES_INVOKED';
export const FETCH_ALL_LANGUAGES = 'FETCH_ALL_LANGUAGES';
export const FETCH_ALL_LANGUAGES_ERROR = 'FETCH_ALL_LANGUAGES_ERROR';
export const FETCH_ALL_LANGUAGES_PLATFORMS = 'FETCH_ALL_LANGUAGES_PLATFORMS';
export const FETCH_ALL_LANGUAGES_PLATFORMS_INVOKED = 'FETCH_ALL_LANGUAGES_PLATFORMS_INVOKED';
export const FETCH_ALL_LANGUAGES_PLATFORMS_ERROR = 'FETCH_ALL_LANGUAGES_PLATFORMS_ERROR';
export const GET_LANGUAGE_DATA_INVOKED = 'GET_LANGUAGE_DATA_INVOKED';
export const GET_LANGUAGE_DATA_ERROR = 'GET_LANGUAGE_DATA_ERROR';
export const GET_LANGUAGE_DATA = 'GET_LANGUAGE_DATA';
export const UPDATE_LANGUAGE_DATA = 'UPDATE_LANGUAGE_DATA';
export const CHANGE_LANGUAGE_STATUS = 'CHANGE_LANGUAGE_STATUS';
export const ADD_NEW_LANGUAGE = 'ADD_NEW_LANGUAGE';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';

//Competitions
export const GET_COMPETITIONS_DATA = 'GET_COMPETITIONS_DATA';
export const GET_COMPETITIONS_DETAILS = 'GET_COMPETITIONS_DETAILS';
export const SYNC_COMPETITIONS_DATA = 'SYNC_COMPETITIONS_DATA';
export const FETCH_COMPETITIONS_DATA = 'SYNC_COMPETITIONS_DATA';
export const GET_COMPETITIONS_TEAMS_DATA = 'GET_COMPETITIONS_TEAMS_DATA';
export const SET_COMPETITIONS_TEAMS_LOGO = 'SET_COMPETITIONS_TEAMS_LOGO';
export const SET_COMPETITIONS_TEAMS_TOP = 'SET_COMPETITIONS_TEAMS_TOP';
export const SET_COMPETITIONS_BROADCAST = 'SET_COMPETITIONS_BROADCAST';
export const FETCH_COMPETITIONS_SEASON = 'FETCH_COMPETITIONS_SEASON';
export const TOP_COMPETITIONS_DATA = 'TOP_COMPETITIONS_DATA';
export const GET_TOP_COMPETITIONS = 'GET_TOP_COMPETITIONS';
export const FETCH_COMPETITIONS_DETAILS = 'FETCH_COMPETITIONS_DETAILS';

//Teams
export const GET_TEAMS_DATA = 'GET_TEAMS_DATA';
export const SET_TOP_TEAM = 'SET_TOP_TEAM';
export const GET_MATCH_DETAIL = 'GET_MATCH_DETAIL';
export const GET_MATCH_ODDS = 'GET_MATCH_ODDS';
export const GET_MATCH_PLAYERS = 'GET_MATCH_PLAYERS';
export const GET_MATCH_EVENTS = 'GET_MATCH_EVENTS';
export const ADD_MATCH_EVENTS = 'ADD_MATCH_EVENTS';
export const REMOVE_MATCH_EVENTS = 'REMOVE_MATCH_EVENTS';
export const PATCH_MATCH_ODDS = 'PATCH_MATCH_ODDS';

//Official Championship
export const ADD_OFFICIAL_CHAMPHIONSHIPS = 'ADD_OFFICIAL_CHAMPHIONSHIPS';
export const GET_OFFICIAL_CHAMPHIONSHIPS = 'GET_OFFICIAL_CHAMPHIONSHIPS';
export const DELETE_OFFICIAL_CHAMPHIONSHIPS = 'DELETE_OFFICIAL_CHAMPHIONSHIPS';
export const PUBLISH_OFFICIAL_CHAMPHIONSHIPS = 'PUBLISH_OFFICIAL_CHAMPHIONSHIPS';
export const GET_OFFICIAL_CHAMPHIONSHIPS_DETAILS = 'GET_OFFICIAL_CHAMPHIONSHIPS_DETAILS';
export const PATCH_OFFICIAL_CHAMPHIONSHIPS_DETAILS = 'PATCH_OFFICIAL_CHAMPHIONSHIPS_DETAILS';
export const GET_OFFICIAL_CHAMPHIONSHIPS_MATCHES = 'GET_OFFICIAL_CHAMPHIONSHIPS_MATCHES';
export const GET_OFFICIAL_CHAMPHIONSHIPS_LANG = 'GET_OFFICIAL_CHAMPHIONSHIPS_LANG';

//Rules
export const GET_ALL_RULES = 'GET_ALL_RULES';
export const CREATE_NEW_RULE = 'CREATE_NEW_RULE';
export const EDIT_RULE_STATUS = 'EDIT_RULE_STATUS';

//EditBar
export const GET_BAR_DETAIL = 'GET_BAR_DETAIL';
export const EDIT_BAR_DETAIL = 'EDIT_BAR_DETAIL';

//QR
export const SCAN_QR_CODE = 'SCAN_QR_CODE';
export const VALID_QR_CODE = 'VALID_QR_CODE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
