import mock from '../mockConfig';
import { idGenerator } from '../../utils/commonHelper';
import { bars, foldersList, labelsList } from '../../@fake-db/bars';

let labels = labelsList;
let barsList = bars;

mock.onGet('/bar/labels').reply(200, labelsList);

mock.onPost('/bar/labels').reply(request => {
  const { label } = JSON.parse(request.data);
  let newLabel = { ...label, id: idGenerator(), slug: label.name.toLowerCase() };
  labels = labels.push(newLabel);
  return [200, newLabel];
});

mock.onPut('/bar/labels').reply(request => {
  const { label } = JSON.parse(request.data);
  labels = labels.map(item => (item.id === label.id ? label : item));
  return [200];
});

mock.onPut('/bar/labels/delete').reply(request => {
  const { labelId } = JSON.parse(request.data);
  labels = labels.filter(item => item.id !== labelId);
  return [200];
});

mock.onGet('/bar').reply(config => {
  const { params } = config;
  const { selectedFolder, selectedLabel, searchText } = params;
  let folderBars = [];

  if (searchText) {
    folderBars = barsList.filter(bar => {
      return (
        bar.name.toLowerCase().includes(searchText.toLowerCase()) ||
        bar.country.toLowerCase().includes(searchText.toLowerCase()) ||
        bar.competition.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }

  if (selectedFolder) {
    if (selectedFolder === 'starred') {
      folderBars = barsList.filter(bar => bar.starred);
    } else if (selectedFolder === 'frequent') {
      folderBars = barsList.filter(bar => bar.frequent);
    } else {
      folderBars = barsList.filter(bar => bar.folder === selectedFolder);
    }
  }

  if (selectedLabel) {
    folderBars = barsList.filter(bar => bar.labels.includes(selectedLabel));
  }

  const total = folderBars.length;

  return [200, { folderBars, total }];
});

mock.onPut('/bar/update-starred').reply(request => {
  const { barIds, status } = JSON.parse(request.data);
  barsList = barsList.map(bar => {
    if (barIds.includes(bar.id)) {
      bar.starred = status;
      return bar;
    } else {
      return bar;
    }
  });
  return [200];
});

mock.onPut('/bar/delete').reply(request => {
  const { barIds } = JSON.parse(request.data);
  barsList = barsList.map(bar => {
    if (barIds.includes(bar.id)) {
      bar.folder = 'trash';
      return bar;
    } else {
      return bar;
    }
  });
  return [200];
});

mock.onPut('/bar/update-label').reply(request => {
  const { barIds, label } = JSON.parse(request.data);
  barsList = barsList.map(bar => {
    if (barIds.includes(bar.id)) {
      const newLabel = bar.labels.find(item => item === label);
      if (newLabel) {
        bar.labels = bar.labels.filter(item => item !== label);
      } else {
        bar.labels = bar.labels.concat(label);
      }
      return bar;
    } else {
      return bar;
    }
  });
  const updatedBars = barsList.filter(bar => barIds.includes(bar.id));
  return [200, updatedBars];
});

mock.onPost('/bar').reply(request => {
  const { bar } = JSON.parse(request.data);
  const newBar = {
    id: idGenerator(),
    frequent: false,
    starred: false,
    labels: [],
    folder: 'bars',
    ...bar,
  };
  barsList = [newBar, ...barsList];
  return [200, newBar];
});

mock.onPut('/bar').reply(request => {
  const { bar } = JSON.parse(request.data);
  barsList = barsList.map(item => (item.id === bar.id ? bar : item));
  return [200];
});

mock.onGet('/bar/counter').reply(config => {
  const counter = { folders: {}, labels: {} };
  foldersList.map(item => {
    if (item.slug === 'starred') {
      counter.folders[item.id] = barsList.filter(bar => bar.starred).length;
    } else if (item.slug === 'frequent') {
      counter.folders[item.id] = barsList.filter(bar => bar.frequent).length;
    } else {
      counter.folders[item.id] = barsList.filter(bar => bar.folder === item.slug).length;
    }
    return null;
  });

  labelsList.map(item => {
    counter.labels[item.id] = barsList.filter(bar => bar.labels.includes(item.id)).length;
    return null;
  });

  return [200, counter];
});
