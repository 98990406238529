import React, { useContext, useEffect, useState } from 'react';
import CmtCard from '../../../@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import RecentTable from './RecentTable';
import { getMatchContainerHeight } from '../../../constants/AppConstants';
import AppContext from '../../../components/contextProvider/AppContextProvider/AppContext';
import { getTodayDate, getYesterdayDate } from '../../../utils/dateHelper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCompetitions,
  getCompetitions,
  getTopCompetitions,
  fetchCompetitionsDetails,
} from '../../../redux/actions/Competitions';
import TablePagination from '@material-ui/core/TablePagination';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const actions = [
  {
    label: 'Today',
    value: getTodayDate(),
  },
  {
    label: 'Yesterday',
    value: getYesterdayDate(),
  },
  {
    label: 'This Week',
    value: 'this_week',
  },
];

const CompetitionSearch = props => {
  const [value, setValue] = useState(props.value);
  const submitVal = () => {
    if (value && value.length > 0) {
      props.submit(value);
    } else {
      props.submit('');
    }
  };
  return (
    <FormControl className={props.classes.margin}>
      <InputLabel htmlFor="input-with-icon-adornment">Search for Competition</InputLabel>
      <Input
        style={{ marginRight: '20px' }}
        id="input-with-icon-adornment"
        defaultValue={value}
        onChange={e => {
          setValue(e.target.value);
        }}
      />
      <Button variant="contained" color="primary" onClick={submitVal} startIcon={<SearchIcon />}>
        Search
      </Button>
    </FormControl>
  );
};

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    padding: '0 !important',
  },
  titleRoot: {
    letterSpacing: 0.15,
  },
  scrollbarRoot: {
    height: props => `calc(100vh - ${props.height}px - 10px)`,
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
  margin: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const CompetitionApp = ({ width }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({});
  const [tableDataList, setTableDataList] = useState({});
  const { competitionsList } = useSelector(({ competitions }) => competitions);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [viewTop, setViewTop] = React.useState(false);

  const viewTopCompetitions = () => {
    if (viewTop == false) {
      dispatch(getTopCompetitions());
    } else {
      dispatch(getCompetitions());
    }
    setViewTop(!viewTop);
  };

  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter),
  });

  const handleChangePage = (event, newPage) => {
    setTableData(tableDataList.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) || {});
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setTableData(tableDataList.slice(page, rowsPerPage) || {});
  };

  const submit = valueSearch => {
    const searchList = [];
    if (valueSearch.length > 0) {
      competitionsList.forEach((value, key) => {
        if (valueSearch && value.name.toLowerCase().includes(valueSearch.toLowerCase())) {
          searchList.push(value);
        }
        if (valueSearch && value.country.name.toLowerCase().includes(valueSearch.toLowerCase())) {
          searchList.push(value);
        }
      });
      setTableDataList(searchList);
      setCount(searchList.length);
      setPage(0);
      setTableData(searchList.slice(0, rowsPerPage));
    } else {
      setTableDataList(competitionsList);
      setCount(competitionsList.length);
      setPage(0);
      setTableData(competitionsList.slice(0, rowsPerPage));
    }
  };

  const sortByName = () => {
    let newSort = competitionsList.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    setTableData(newSort.slice(0, 30));
    setTableDataList(newSort);
    setPage(0);
  };
  const sortByCountry = () => {
    let newSort = competitionsList.sort((a, b) =>
      a.country.name > b.country.name ? 1 : b.country.name > a.country.name ? -1 : 0,
    );
    setTableData(newSort.slice(0, 30));
    setTableDataList(newSort);
    setPage(0);
  };

  const sortBySync = () => {
    let newSort = competitionsList.sort((a, b) => (a.shouldSync < b.shouldSync ? 1 : b.shouldSync < a.shouldSync ? -1 : 0));
    setTableData(newSort.slice(0, 30));
    setTableDataList(newSort);
    setPage(0);
  };

  useEffect(() => {
    if (viewTop == false) {
      dispatch(getCompetitions());
    } else {
      dispatch(getTopCompetitions());
    }
    if (competitionsList !== undefined) {
      setTableData(competitionsList.slice(0, 30));
      setTableDataList(competitionsList);
    }
  }, [dispatch]);

  useEffect(() => {
    if (competitionsList !== undefined && competitionsList.length > 0) {
      setTableData(competitionsList.slice(0, 30));
      setTableDataList(competitionsList);
      setCount(competitionsList.length);
    }
  }, [competitionsList]);

  const fetchAllCompetitions = () => {
    dispatch(fetchCompetitions());
  };

  const fetchAllDetailsCompetitions = () => {
    let futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 7);
    let backDate = new Date();
    backDate.setDate(backDate.getDate() - 7);

    dispatch(fetchCompetitionsDetails(backDate.toISOString(), futureDate.toISOString()));
  };
  // const filterTableData = event => {
  //   setMenu(event.label);
  //   switch (event.value) {
  //     case getTodayDate(): {
  //       return setTableData(competitons.list.filter(item => item.orderDate === event.value));
  //     }
  //     case getYesterdayDate(): {
  //       return setTableData(competitons.list.filter(item => item.orderDate === event.value));
  //     }
  //     case 'this_week': {
  //       return setTableData(
  //         competitons.list.filter(item => item.orderDate !== getTodayDate() && item.orderDate !== getYesterdayDate()),
  //       );
  //     }
  //     default:
  //       return setTableData(competitons.list);
  //   }
  // };

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title={'Competitions'}
        style={{ marginBottom: '30px' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <CompetitionSearch classes={classes} submit={submit} />
        <Button
          variant="contained"
          style={{ height: '100%', marginLeft: '15px' }}
          color="primary"
          onClick={fetchAllCompetitions}>
          FETCH
        </Button>
        <Button
          variant="contained"
          style={{ height: '100%', marginLeft: '15px' }}
          color="primary"
          onClick={fetchAllDetailsCompetitions}>
          FETCH DETAILS
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ height: '100%', marginLeft: '15px' }}
          onClick={viewTopCompetitions}>
          {viewTop == true ? 'ALL COMPETITIONS' : 'TOP COMPETITIONS'}
        </Button>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <RecentTable sortByName={sortByName} sortByCountry={sortByCountry} sortBySync={sortBySync} tableData={tableData} />
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={count}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
  );
};

export default CompetitionApp;
