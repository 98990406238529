import React, {useState} from 'react';
import {Box} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import useStyles from './../index.style';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import Tooltip from '@material-ui/core/Tooltip';
import CmtAvatar from "../../../../@coremat/CmtAvatar";
import TablePagination from "@material-ui/core/TablePagination";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Input from "@material-ui/core/Input/Input";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import SearchIcon from '@material-ui/icons/Search';
import Moment from 'moment';


const MatchSearch = (props) =>{
  const [value, setValue] = useState(props.value);
  const submitVal = () =>{
    if(value && value.length > 0){
      props.submit(value)
    }else{
      props.submit('')
    }
  }
  return (
    <FormControl style={{width: '100%', display: 'block'}}>
      <Box style={{display: 'inline-block'}}>
        <InputLabel htmlFor="input-with-icon-adornment">Search for Matches</InputLabel>
        <Input style={{marginRight: '20px'}}
               id="input-with-icon-adornment"
               defaultValue={value}
               onChange={e =>{
                 setValue(e.target.value)
               }}
               onKeyPress={event => {
                 if (event.key  === 'Enter') {
                   submitVal()
                 }
               }}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={submitVal}  startIcon={<SearchIcon />}>
        Search
      </Button>
    </FormControl>
  )
}

const SelectMatches = ({match, selectMatch}) =>{
  const classes = useStyles();


  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(match.count - match.matches.filter((item, id) => item.status =='POSTP').length);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);

  const [matchFull, setMatchFull] = useState(match.matches.filter((item, id) => item.status !='POSTP'));
  const [matchPagination, setMatchPagination] = useState(matchFull.slice(0, 30));

  const handleChangePage = (event, newPage) =>{

    setMatchFull(matchFull)
    setMatchPagination(matchFull.slice(newPage*rowsPerPage, (newPage*rowsPerPage)+rowsPerPage) ||{});
    setPage(newPage);
  };


  const submit = (valueSearch) =>{

    const searchList = [];
    let newMatchList = match.matches.filter((item, id) => item.status !='POSTP');
    if(valueSearch.length > 0){
      newMatchList.forEach((value, key ) => {
        if(valueSearch && value.headline.toLowerCase().includes(valueSearch.toLowerCase())){
          searchList.push(value)
        }else if(valueSearch && value.competitionName.toLowerCase().includes(valueSearch.toLowerCase())){
          searchList.push(value)
        }
      });
      setMatchFull(searchList)
      setCount(searchList.length)
      setPage(0);
      setMatchPagination(searchList.slice(0, rowsPerPage))
    }else{
      setMatchFull(match.matches.filter((item, id) => item.status !='POSTP'));
      setCount(match.count - match.matches.filter((item, id) => item.status =='POSTP').length);
      setPage(0);
      setMatchPagination(matchFull.slice(0, rowsPerPage))
    }

  }

  return(
    <Box className={classes.selectListMatch}>
      <Box style={{marginBottom: '20px'}}>
        <MatchSearch classes={classes} submit={submit}/>
      </Box>
      <Box className={classes.selectListMatchContainer}>

      {
        matchPagination.map((match, id) =>{
          return(
            <Box key={id} className={classes.selectListMatchRow}>
              <Box>
                <Box mr={4} style={{display: 'flex', flexDirection: 'row'}}>
                  <Box mr={4} style={{width: '30px', overflow: 'hidden', marginRight: '0', borderRight: '1px solid #fff'}}>
                    <CmtAvatar size={60} src={match.homeTeamEmblemUrl} alt={match.headline} />
                  </Box>
                  <Box mr={4} style={{width: '30px', overflow: 'hidden', marginRight: '0'}}>
                    <CmtAvatar size={60} style={{transform: 'translateX(-30px)'}} src={match.awayTeamEmblemUrl} alt={match.headline} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box><h3 style={{fontWeight: 'bold'}}>{match.headline}</h3></Box>
                <Box>{
                  Moment(match.scheduledDate).format('H:mm - D MMM yyyy')
                }</Box>
                <Box>{match.competitionName}</Box>
              </Box>
              <Button style={{marginLeft: 'auto'}}  variant="contained" color="primary"
                       onClick={()=>selectMatch(match._id, match.headline, match.scheduledDate, match.homeTeamEmblemUrl, match.awayTeamEmblemUrl, match.competitionName)}>
                Add
              </Button>
            </Box>
          )
        })
      }
      <TablePagination
        component="div"
        style={{order: '9999', width: '100%'}}
        count={count}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPageOptions={[30]}
        rowsPerPage={rowsPerPage}
        // onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </Box>
    </Box>
  )
}

export default SelectMatches;
