import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import useStyles from './index.style';
import AppHeader from './AppHeader';
import clsx from 'clsx';
import Sidebar from './Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import MatchesList from './MatchesList';
import MatchDetail from './MatchDetail';
import CreateMatch from './CreateMatch';
import { setCurrentMatch } from '../../../redux/actions/MatchApp';

const MatchApp = () => {
  const classes = useStyles();
  const { isSideBarCollapsed } = useSelector(({ matchApp }) => matchApp);
  const [viewMode, setViewMode] = useState('table');
  const [showMatchDetail, setShowMatchDetail] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const dispatch = useDispatch();

  const onChangeViewMode = mode => {
    setViewMode(mode);
  };

  const onShowMatchDetail = match => {
    dispatch(setCurrentMatch(match));
    setShowMatchDetail(true);
  };

  const onHideMatchDetail = () => {
    dispatch(setCurrentMatch(null));
    setShowMatchDetail(false);
  };

  const onClickCreateMatch = () => {
    setOpenCreateDialog(true);
  };

  const onClickEditMatch = match => {
    dispatch(setCurrentMatch(match));
    setOpenCreateDialog(true);
  };

  const onCloseComposeDialog = () => {
    dispatch(setCurrentMatch(null));
    setOpenCreateDialog(false);
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    window.addEventListener('load', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });
  const updateWidthAndHeight = () => {
    if (window.innerWidth < 769) {
      setViewMode('grid');
    } else {
      setViewMode('table');
    }
  };
  return (
    <Box className={classes.inBuildAppCard}>
      <AppHeader onChangeViewMode={onChangeViewMode} viewMode={viewMode} />
      <Box className={clsx(classes.inBuildAppContainer, isSideBarCollapsed ? 'collapsed' : '')}>
        <Sidebar onClickCreateMatch={onClickCreateMatch} />
        <MatchesList viewMode={viewMode} onShowMatchDetail={onShowMatchDetail} onClickEditMatch={onClickEditMatch} />
      </Box>
      {showMatchDetail && <MatchDetail open={showMatchDetail} handleDialog={onHideMatchDetail} />}
      {openCreateDialog && <CreateMatch open={openCreateDialog} handleDialog={onCloseComposeDialog} />}
    </Box>
  );
};

export default MatchApp;
