import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CmtCard from '../../../@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import RecentTable from './RecentTable';
import { getMatchContainerHeight } from '../../../constants/AppConstants';
import AppContext from '../../../components/contextProvider/AppContextProvider/AppContext';
import { getTodayDate, getYesterdayDate } from '../../../utils/dateHelper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompetitionsTeams,
  topCompetitions,
  getCompetitionsDetails,
  syncCompetitions,
  fetchCompetitions,
} from '../../../redux/actions/Competitions';
import TablePagination from '@material-ui/core/TablePagination';
import { Box } from '@material-ui/core';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch/Switch';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

import Broadcasts from './Broadcasts';

import { GET_COMPETITIONS_TEAMS_DATA } from '../../../constants/ActionTypes';
import { fetchAllLanguages } from '../../../redux/actions/Languages';

const actions = [
  {
    label: 'Today',
    value: getTodayDate(),
  },
  {
    label: 'Yesterday',
    value: getYesterdayDate(),
  },
  {
    label: 'This Week',
    value: 'this_week',
  },
];

const CompetitionSearch = props => {
  const [value, setValue] = useState(props.value);
  const submitVal = () => {
    if (value && value.length > 0) {
      props.submit(value);
    } else {
      props.submit('');
    }
  };
  return (
    <FormControl className={props.classes.margin}>
      <InputLabel htmlFor="input-with-icon-adornment">Search for Teams</InputLabel>
      <Input
        style={{ marginRight: '20px' }}
        id="input-with-icon-adornment"
        defaultValue={value}
        onChange={e => {
          setValue(e.target.value);
        }}
      />
      <Button variant="contained" color="primary" onClick={submitVal} startIcon={<SearchIcon />}>
        Search
      </Button>
    </FormControl>
  );
};

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    padding: '0 !important',
  },
  titleRoot: {
    letterSpacing: 0.15,
  },
  scrollbarRoot: {
    height: props => `calc(100vh - ${props.height}px - 10px)`,
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
  margin: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableHead: {
    width: 'auto',
    marginRight: '30px',
  },
}));

const CompetitionDetailApp = ({ width }) => {
  const { id } = useParams();
  const history = useHistory();
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({});
  const [tableDataList, setTableDataList] = useState({});
  const { teamsList, competitionDetail } = useSelector(({ competitions }) => competitions);
  const { languageList, languages } = useSelector(({ languages }) => languages);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const [sync, setSync] = React.useState(competitionDetail ? competitionDetail.shouldSync : false);
  const [top, setTop] = React.useState(competitionDetail ? competitionDetail.topCompetition : false);

  const handleChange = event => {
    setSync(event.target.checked);
    dispatch(syncCompetitions(competitionDetail._id, event.target.checked));
  };
  const handleChangeTop = event => {
    setTop(event.target.checked);
    dispatch(topCompetitions(competitionDetail._id, event.target.checked));
  };

  const handleFetch = () => {
    dispatch(fetchCompetitions());
  };

  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter),
  });

  const handleChangePage = (event, newPage) => {
    setTableData(tableDataList.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) || {});
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setTableData(tableDataList.slice(page, rowsPerPage) || {});
  };

  const submit = valueSearch => {
    const searchList = [];
    if (valueSearch.length > 0) {
      teamsList.forEach((value, key) => {
        if (valueSearch && value.name.toLowerCase().includes(valueSearch.toLowerCase())) {
          searchList.push(value);
        }
      });
      setTableDataList(searchList);
      setCount(searchList.length);
      setPage(0);
      setTableData(searchList.slice(0, rowsPerPage));
    } else {
      setTableDataList(teamsList);
      setCount(teamsList.length);
      setPage(0);
      setTableData(teamsList.slice(0, rowsPerPage));
    }
  };

  useEffect(() => {
    dispatch(getCompetitionsTeams(id));
    dispatch(getCompetitionsDetails(id));
    dispatch(fetchAllLanguages());
  }, [dispatch]);

  useEffect(() => {
    if (competitionDetail !== undefined) {
      setSync(competitionDetail.shouldSync);
      setTop(competitionDetail.topCompetition);
    }
  }, [competitionDetail]);

  useEffect(() => {
    return () => {
      dispatch({ type: GET_COMPETITIONS_TEAMS_DATA, payload: {} });
    };
  }, []);

  useEffect(() => {
    if (teamsList !== undefined) {
      setTableData(teamsList.slice(0, 30));
      setTableDataList(teamsList);
      setCount(teamsList.length);
    }
  }, [teamsList]);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        icon={
          <ArrowBackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/competitions');
            }}
          />
        }
        title={competitionDetail != undefined ? competitionDetail.name + ' - ' + competitionDetail.country.name : ''}
        style={{ display: 'flex', flexDirection: 'column' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Table className={classes.tableHead}>
            <TableHead>
              <TableRow />
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCellRoot}>
                  <Button variant="contained" color="primary" onClick={handleOpen}>
                    Broadcast
                  </Button>
                </TableCell>
                <TableCell className={classes.tableCellRoot}>Should sync</TableCell>
                <TableCell className={classes.tableCellRoot}>
                  <Switch
                    checked={sync}
                    onChange={handleChange}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
                <TableCell className={classes.tableCellRoot}>Top Competition</TableCell>
                <TableCell className={classes.tableCellRoot}>
                  <Switch
                    checked={top}
                    onChange={handleChangeTop}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
                <TableCell className={classes.tableCellRoot}>
                  <Button variant="contained" color="primary" onClick={handleFetch}>
                    FETCH SEASON
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CompetitionSearch classes={classes} submit={submit} />
          </Box>
        </Box>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <RecentTable tableData={tableData} />
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={count}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </PerfectScrollbar>
      </CmtCardContent>
      <Broadcasts
        competitionId={id}
        languageList={languages}
        open={open}
        broadcasters={competitionDetail !== undefined ? competitionDetail.broadcasters : null}
        handleCloseModal={handleCloseModal}
      />
    </CmtCard>
  );
};

export default CompetitionDetailApp;
