import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import BarApp from './BarApp';
import Teams from './Teams';
import Languages from './Languages';
import Competitions from './Competitions';
import MatchApp from './MatchApp';
import MatchDetails from './MatchDetails';
import OfficialChampionshipApp from './OfficialChampionshipApp';
import OfficialChampionship from './OfficialChampionship';
import Rules from './Rules';
import BarPage from './BarPage';
import QrCode from './QrCode';
import Users from './Users';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    matchApp: MatchApp,
    matchDetails: MatchDetails,
    barApp: BarApp,
    barPage: BarPage,
    languages: Languages,
    teams: Teams,
    rules: Rules,
    users: Users,
    qrCode: QrCode,
    competitions: Competitions,
    officialChampionshipApp: OfficialChampionshipApp,
    officialChampionship: OfficialChampionship,
  });
