import React, { useEffect } from 'react';
import IntlMessages from '../../../utils/IntlMessages';
import Box from '@material-ui/core/Box';
import { Button, fade, IconButton, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField/TextField';
import { getUsers, patchUser } from '../../../redux/actions/Users';
import { changePasswordAdmin } from '../../../redux/actions/Users';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      minWidth: '400px',
      maxWidth: '400px',
      '@media screen and (max-width: 480px)': {
        maxWidth: '300px',
        minWidth: '300px',
      },
    },
  },
  inBuildAppCard: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    margin: '15px',
    padding: '25px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '460px',
    '@media screen and (max-width: 480px)': {
      maxWidth: '360px',
    },
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassowrd] = React.useState('');
  const [repeatNewPassword, setRepeatNewPassword] = React.useState('');
  const { changePassword } = useSelector(({ users }) => users);
  const [error, setError] = React.useState(null);
  const [matchPassError, setMatchPassError] = React.useState(null);

  const handleOldPassword = e => {
    setOldPassword(e.target.value);
  };

  const handleNewPassword = e => {
    if (e.target.value.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$') != null) {
      if (repeatNewPassword.length > 0) {
        if (repeatNewPassword == e.target.value) {
          setNewPassowrd(e.target.value);
          setError(false);
          setMatchPassError(false);
        } else {
          setMatchPassError(true);
        }
      } else {
        setNewPassowrd(e.target.value);
        setError(false);
      }
    } else {
      if (e.target.value.length == 0) {
        setError(null);
      } else {
        setError(true);
      }
    }
  };

  const handleRepeatNewPassword = e => {
    if (e.target.value == newPassword) {
      setRepeatNewPassword(e.target.value);
      setMatchPassError(false);
      setError(false);
    } else {
      setMatchPassError(true);
    }
  };

  const submitChangePassword = () => {
    dispatch(
      changePasswordAdmin({
        oldPassword: oldPassword,
        password: newPassword,
      }),
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.inBuildAppCard}>
        <Box style={{ marginBottom: '20px' }}>
          <h2>Change Password</h2>
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ maxWidth: '300px', width: '100%' }}
            onChange={handleOldPassword}
            label="Old Password"
            variant="outlined"
            type="password"
          />
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ maxWidth: '300px', width: '100%' }}
            onChange={handleNewPassword}
            label="New Password"
            variant="outlined"
            type="password"
          />
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ maxWidth: '300px', width: '100%' }}
            onChange={handleRepeatNewPassword}
            label="Confirm New Password"
            variant="outlined"
            type="password"
          />
        </Box>
        <Box>
          {error == true && <p>At least 8 characters, one uppercase, one lowercase and one number</p>}
          {matchPassError == true && <p>New password and confirm password does not match</p>}
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            style={{ maxHeight: '40px', marginTop: '10px', marginLeft: '5px' }}
            disabled={
              error == true ||
              error == null ||
              matchPassError == true ||
              matchPassError == null ||
              oldPassword.length < 2 ||
              newPassword != repeatNewPassword
            }
            onClick={submitChangePassword}>
            Change Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
