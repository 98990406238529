import {
  TOGGLE_SIDEBAR_COLLAPSED,
  FETCH_ALL_LANGUAGES_INVOKED,
  FETCH_ALL_LANGUAGES,
  FETCH_ALL_LANGUAGES_ERROR,
  FETCH_ALL_LANGUAGES_PLATFORMS,
  GET_LANGUAGE_DATA,
  UPDATE_LANGUAGE_DATA,
  GET_LANGUAGE_DATA_INVOKED,
  GET_LANGUAGE_DATA_ERROR,
  CHANGE_LANGUAGE_STATUS,
  ADD_NEW_LANGUAGE,
  DELETE_LANGUAGE,
} from '../../constants/ActionTypes';

// ------------------------------------
// Action Handlers
// ------------------------------------

const INIT_STATE = {
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    selectedFolder: 'accepted',
    selectedLabel: '',
    searchText: '',
  },
  languageList: [],
  languages: [],
  newLanguageCallback: null,
  languagePlatforms: [],
  currentLanguage: null,
  totalBars: null,
  counter: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSideBarCollapsed: action.payload ? action.payload : !state.isSideBarCollapsed,
      };
    }

    case GET_LANGUAGE_DATA: {
      return {
        ...state,
        languageList: action.payload.data,
      };
    }

    case FETCH_ALL_LANGUAGES_PLATFORMS: {
      return {
        ...state,
        languagePlatforms: action.payload.data,
      };
    }

    case FETCH_ALL_LANGUAGES: {
      return {
        ...state,
        languages: action.payload.data,
      };
    }

    case UPDATE_LANGUAGE_DATA: {
      return {
        ...state,
        languageList: action.data,
      };
    }

    case CHANGE_LANGUAGE_STATUS: {
      return {
        ...state,
        languageStatus: action.data,
      };
    }

    case ADD_NEW_LANGUAGE: {
      return {
        ...state,
        newLanguageCallback: action.data,
      };
    }

    case DELETE_LANGUAGE: {
      return {
        ...state,
        deletedLanguage: action.data,
      };
    }

    default:
      return state;
  }
};
