import React from 'react';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportBars = ({ children, data }) => {
  const bars = data ? data.map(item => {
    item.dateStrings = item.dates.map(bar => bar.date).join(',');
    return item;
  }): [];
  return (
    <ExcelFile element={children}>
      <ExcelSheet data={bars} name="Bars">
        <ExcelColumn label="Id" value="id" />
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="BarType" value="barType" />
        <ExcelColumn label="Country" value="country" />
        <ExcelColumn label="Dates" value="dateStrings" />
        <ExcelColumn label="Competition" value="competition" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExportBars;
