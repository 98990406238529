import {
  GET_MATCH_DETAIL,
  TOGGLE_SIDEBAR_COLLAPSED,
  GET_MATCH_PLAYERS,
  GET_MATCH_ODDS,
  GET_MATCH_EVENTS,
  ADD_MATCH_EVENTS,
  REMOVE_MATCH_EVENTS,
  PATCH_MATCH_ODDS,
  PATCH_MATCH_PLAYER,
  SET_MATCH_BROADCAST
} from '../..//constants/ActionTypes';
import {Add} from "@material-ui/icons";
import {SET_COMPETITIONS_BROADCAST} from "../../constants/ActionTypes";

const INIT_STATE = {
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    status: 'future',
    competition: '',
    query: '',
  },
  matchesList: [],
  currentMatch: null,
  totalMatches: null,
  counter: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSideBarCollapsed: action.payload ? action.payload : !state.isSideBarCollapsed,
      };
    }

    case GET_MATCH_DETAIL: {
      return {
        ...state,
        matchDetail: action.payload,
      };
    }

    case GET_MATCH_EVENTS: {
      return {
        ...state,
        matchEvents: action.payload,
      };
    }

    case ADD_MATCH_EVENTS: {
      return {
        ...state,
        matchAddEvents: action.payload,
      };
    }

    case REMOVE_MATCH_EVENTS: {
      return {
        ...state,
        matchRemoveEvents: action.payload,
      };
    }

    case GET_MATCH_ODDS: {
      return {
        ...state,
        matchOdds: action.payload,
      };
    }

    case PATCH_MATCH_ODDS: {
      return {
        ...state,
        matchOdds: action.payload,
      };
    }

    case PATCH_MATCH_PLAYER: {
      return {
        ...state,
        playerLineup: action.payload,
      };
    }

    case GET_MATCH_PLAYERS: {
      return {
        ...state,
        playersDetail: action.payload,
      };
    }

    case SET_MATCH_BROADCAST: {
      return {
        ...state,
        broadcastMatch: action.payload.data,
      };
    }

    default:
      return state;
  }
};
