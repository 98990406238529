import React, {useEffect} from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CmtMediaObject from '../../../@coremat/CmtMediaObject';
import Switch from '@material-ui/core/Switch';
import {
  setCompetitionsTeamTop,
  setCompetitionsTeamLogo
} from "../../../redux/actions/Competitions";
import _ from "lodash";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ColorPickerPopup from '../../../components/Common/ColorPickerPopup';
import Avatar from '@material-ui/core/Avatar';


import {useDispatch} from "react-redux";
const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      color: theme.palette.error.main,
      paddingRight: 24,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
  },
  activeSelect:{
    transform: 'translate(14px, -6px) scale(0.75)',
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
  colorpicker: {
    '& .colorPicker-root': {
      height: '30px',
      width: '40px',
      cursor: 'pointer',
    }
  }
}));

function getBgColor(status) {
  const color = {
    cancelled: '#E00930',
    completed: '#0795F4',
    delayed: '#03DAC5',
    onHold: '#FF8C00',
  };
  return color[status];
}

const TableItem = ({ row }) => {
  const classes = useStyles();
  const [topTeam, setTopTeam] = React.useState(row.topTeam);
  const dispatch = useDispatch();
  const [leftColor, setLeftColor] = React.useState(row.teamLogo ? row.teamLogo.leftColor : '#b9b9b9');
  const [rightColor, setRightColor] = React.useState(row.teamLogo ? row.teamLogo.rightColor : '#b9b9b9');
  const [typeLogo, setTypeLogo] = React.useState(row.teamLogo ? row.teamLogo.type : 1);


  const handleChangeTop = event => {
    setTopTeam(event.target.checked);
    let status = {
      'teamId': row._id,
      'status': event.target.checked
    }
    dispatch(setCompetitionsTeamTop(status))
  };
  const setNewTypeLogo = (event) => {
    setTypeLogo(parseInt(event.target.value));
    const changeNewLogo = {
      'teamId': row._id,
      'teamLogo': {
        'type': parseInt(event.target.value),
        'leftColor': leftColor,
        'rightColor': rightColor
      }
    };
    dispatch(setCompetitionsTeamLogo(changeNewLogo))
  };
  const setNewLeftColor = (event) => {
    setLeftColor(event);
    const changeNewLogo = {
      'teamId': row._id,
      'teamLogo': {
        'type': typeLogo,
        'leftColor': event,
        'rightColor': rightColor
      }
    };
    dispatch(setCompetitionsTeamLogo(changeNewLogo))
  };
  const setNewRighttColor = (event) => {
    setRightColor(event);
    const changeNewLogo = {
      'teamId': row._id,
      'teamLogo': {
        'type': typeLogo,
        'leftColor': leftColor,
        'rightColor': event
      }
    };
    dispatch(setCompetitionsTeamLogo(changeNewLogo))
  };

  useEffect(() => {
    setTopTeam(row.topTeam)
  }, [row]);



  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>
        <Avatar alt={row.name} src={row.emblemUrl} />
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <CmtMediaObject
          avatarPos="center"
          title={row.name}
          titleProps={{
            variant: 'h5',
            className: classes.titleRoot,
          }}
        />
      </TableCell>
      <TableCell className={classes.tableCellRoot}><Switch
        checked={topTeam}
        onChange={handleChangeTop}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      /></TableCell>
      <TableCell className={clsx(classes.tableCellRoot, 'success')}>
        <Box className={classes.badgeRoot} component="span" bgcolor={getBgColor(row.status)} style={{display: 'flex', flexWrap: 'no-wrap', alignItems: 'center'}}>
          <FormControl variant="outlined" className={classes.formControl} style={{marginRight: '15px'}}>
            <InputLabel htmlFor="outlined-age-native-simple" className={classes.activeSelect}>Type</InputLabel>
            <Select
              native
              value={typeLogo}
              onChange={setNewTypeLogo}
              label="Type"
              inputProps={{
                name: 'typeLogo',
                id: 'outlined-age-native-simple',
              }}>
              {_.times(11, (i) => (
                <option key={i+1} value={i+1}>{i+1}</option>
                ))
              }
            </Select>
          </FormControl>
          <Box className={classes.colorpicker} style={{display: 'inline-flex'}}>
            <ColorPickerPopup color={leftColor} setColor={setNewLeftColor} />
            <ColorPickerPopup color={rightColor} setColor={setNewRighttColor} />
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
