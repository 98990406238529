import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  tableCellRoot: {
    padding: '6px 10px',
    color: theme.palette.text.disabled,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 15,
    },
    '&:last-child': {
      paddingRight: 15,
    },
  },
}));

const ListHeader = ({ filterType, matchesList, checkedMatches, handleHeaderCheckBox }) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCellRoot}>
          <Box display="flex" alignItems="center">
            Name
          </Box>
        </TableCell>
        {filterType == 'past' || filterType == 'live' ? (
          <TableCell className={classes.tableCellRoot}>Score</TableCell>
        ) : null}
        <TableCell className={classes.tableCellRoot}>Date</TableCell>
        <TableCell className={classes.tableCellRoot}>Competition</TableCell>
        <TableCell className={classes.tableCellRoot}>Broadcast</TableCell>
        <TableCell className={classes.tableCellRoot} />
      </TableRow>
    </TableHead>
  );
};

export default ListHeader;

ListHeader.prototype = {
  checkedMatches: PropTypes.array,
  matchesList: PropTypes.array,
  handleHeaderCheckBox: PropTypes.func,
};

ListHeader.defaultProps = {
  checkedMatches: [],
  matchList: [],
};
