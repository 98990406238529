import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';

axios.interceptors.response.use(
  response => {
    return response;
  },
  async function(error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error.response.data.path !== '/authentication/refresh-token'
    ) {
      originalRequest._retry = true;
      axios
        .post('authentication/refresh-token', '', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('login')).refreshToken,
          },
        })
        .then(res => {
          if (res.data) {
            const access_token = res.data.accessToken;
            originalRequest.headers.Authorization = 'Bearer ' + access_token;
            localStorage.setItem('token', access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
            window.location.reload(false);
          }
        });
    } else if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error.response.data.path === '/authentication/refresh-token'
    ) {
      originalRequest._retry = true;
      localStorage.removeItem('token');
      localStorage.removeItem('login');
      localStorage.removeItem('bar');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/authentication/signup', {
          email: email,
          password: password,
          username: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.accessToken);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.accessToken;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.accessToken));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('/authentication/signin', {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            if (data.accessToken) {
              localStorage.setItem('token', data.accessToken);
              delete data.accessToken;
              localStorage.setItem('login', JSON.stringify(data));
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
              dispatch(fetchSuccess());
              dispatch(setAuthUser(data));
              //dispatch(JWTAuth.getAuthUser(true, data.accessToken));
            } else {
              dispatch(fetchError(data));
            }
          })
          .catch(function(error) {
            if (error.response && typeof error.response.data === 'object') {
              dispatch(fetchError(error.response.data.message));
            } else {
              dispatch(fetchError(error));
            }
          });
      } catch (error) {
        dispatch(fetchError(error));
      }
    };
  },
  onLogout: () => {
    let authToken = localStorage.getItem('token');
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/authentication/signout', '', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then(({ data }) => {
          // if (data) {
          dispatch(fetchSuccess());
          localStorage.removeItem('token');
          localStorage.removeItem('login');
          localStorage.removeItem('bar');

          dispatch(setAuthUser(null));
          // } else {
          //   dispatch(fetchError(data.error));
          // }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      if (!token) {
        dispatch(updateLoadUser(true));
        return false;
      }
      axios
        .post('/authentication/refresh-token')
        .then(({ data }) => {
          if (data.authToken) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {};
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
