import React, {useContext, useEffect, useState} from 'react';
import { useParams } from 'react-router'
import CmtCard from '../../../@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import RecentTable from './RecentTable';
import { getMatchContainerHeight } from '../../../constants/AppConstants';
import AppContext from '../../../components/contextProvider/AppContextProvider/AppContext';
import { getTodayDate, getYesterdayDate } from '../../../utils/dateHelper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useDispatch, useSelector} from "react-redux";
import {
  getTeams
} from "../../../redux/actions/Teams";

import TablePagination from '@material-ui/core/TablePagination';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';

const actions = [
  {
    label: 'Today',
    value: getTodayDate(),
  },
  {
    label: 'Yesterday',
    value: getYesterdayDate(),
  },
  {
    label: 'This Week',
    value: 'this_week',
  },
];

const TeamsSearch = (props) =>{
  const [value, setValue] = useState(props.value);
  const submitVal = () =>{
    if(value && value.length > 0){
      props.submit(value)
    }else{
      props.submit('')
    }
  }
  return (
    <FormControl className={props.classes.margin}>
      <InputLabel htmlFor="input-with-icon-adornment">Search for Teams</InputLabel>
      <Input style={{marginRight: '20px'}}
             id="input-with-icon-adornment"
             defaultValue={value}
             onChange={e =>{
               setValue(e.target.value)
             }}
      />
      <Button variant="contained" color="primary" onClick={submitVal} startIcon={<SearchIcon />}>
        Search
      </Button>
    </FormControl>
  )
}

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    padding: '0 !important',
  },
  titleRoot: {
    letterSpacing: 0.15,
  },
  scrollbarRoot: {
    height: props => `calc(100vh - ${props.height}px - 10px)`,
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
  margin: {
    display: 'flex',
    flexDirection: 'row'
  },
  tableHead: {
    width: 'auto',
    marginRight: '30px'
  }
}));

const CompetitionDetailApp = ({ width }) => {
  const {id} = useParams();
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({});
  const [tableDataList, setTableDataList] = useState({});
  const {teamsList} = useSelector(({teams}) => teams);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);


  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter),
  });

  const handleChangePage = (event, newPage) =>{
    setTableData(tableDataList.slice(newPage*rowsPerPage, (newPage*rowsPerPage)+rowsPerPage) ||{});
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event =>{
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setTableData(tableDataList.slice(page, rowsPerPage) ||{});
  };

  const submit = (valueSearch) =>{
    const searchList = [];
    if(valueSearch.length > 0){
      teamsList.forEach((value, key ) => {
        if(valueSearch && value.name.toLowerCase().includes(valueSearch.toLowerCase())){
          searchList.push(value)
        }
      });
      setTableDataList(searchList);
      setCount(searchList.length);
      setPage(0);
      setTableData(searchList.slice(0, rowsPerPage))
    }else{
      setTableDataList(teamsList);
      setCount(teamsList.length);
      setPage(0);
      setTableData(teamsList.slice(0, rowsPerPage))
    }
  };

  useEffect(() => {
    dispatch(getTeams(id));
  }, [dispatch]);

  useEffect(() => {
    if(teamsList !== undefined ){
      setTableData(teamsList.slice(0, 30));
      setTableDataList(teamsList);
      setCount(teamsList.length);
    }
  }, [teamsList]);

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title='Teams'
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}
      >
        <TeamsSearch classes={classes} submit={submit}/>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <RecentTable tableData={tableData} />
          <TablePagination
            component="div"
            style={{order: '9999', width: '100%'}}
            count={count}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
  );
};

export default CompetitionDetailApp;
