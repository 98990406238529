import React, { useContext, useEffect, useState } from 'react';
import ListTableView from './ListTableView';
import ListGridView from './ListGridView';
import { useDispatch, useSelector } from 'react-redux';
import { getBarsList } from '../../../../redux/actions/BarApp';
import PropTypes from 'prop-types';
import BarsMsg from './BarsMsg';
import { Box } from '@material-ui/core';
import useStyles from '../index.style';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getBarContainerHeight } from '../../../../constants/AppConstants';
import AppContext from '../../../../components/contextProvider/AppContextProvider/AppContext';
import EmptyBarResult from './EmptyBarResult';

const BarsList = ({ width, viewMode, onShowBarDetail, onClickEditBar }) => {
  const { showFooter } = useContext(AppContext);
  const { barsList, filterType  } = useSelector(({ barApp }) => barApp);
  const [checkedBars, setCheckedBars] = useState([]);
  const [showMsg, setShowMsg] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBarsList(filterType));
  }, [dispatch, filterType]);

  const handleCellCheckBox = (isChecked, id) => {
    if (isChecked) {
      setCheckedBars(checkedBars.concat(id));
    } else {
      setCheckedBars(checkedBars.filter(barId => barId !== id));
    }
  };

  const toggleMsgShow = () => {
    setShowMsg(!showMsg);
  };

  const handleHeaderCheckBox = isChecked => {
    if (isChecked) {
      const ids = barsList.map(bar => bar.id);
      updateCheckedBars(ids);
    } else {
      updateCheckedBars([]);
    }
  };

  const updateCheckedBars = barIds => {
    setCheckedBars(barIds);
  };

  const classes = useStyles({
    height: getBarContainerHeight(width, showFooter),
  });


  return barsList.length > 0 ? (
    <Box className={classes.inBuildAppMainContent}>
      <PerfectScrollbar className={classes.perfectScrollbarBarCon}>
        {showMsg && <BarsMsg barsList={barsList} toggleMsgShow={toggleMsgShow} />}
        {viewMode === 'table' ? (
          <ListTableView
            checkedBars={checkedBars}
            handleCellCheckBox={handleCellCheckBox}
            handleHeaderCheckBox={handleHeaderCheckBox}
            updateCheckedBars={updateCheckedBars}
            onShowBarDetail={onShowBarDetail}
            onClickEditBar={onClickEditBar}
          />
        ) : (
          <ListGridView onShowBarDetail={onShowBarDetail} onClickEditBar={onClickEditBar} />
        )}
      </PerfectScrollbar>
    </Box>
  ) : (
    <Box className={classes.inBuildAppMainContent}>
      <EmptyBarResult />
    </Box>
  );
};

export default BarsList;

BarsList.prototype = {
  viewMode: PropTypes.string,
  onShowBarDetail: PropTypes.func,
  onClickEditBar: PropTypes.func,
};

BarsList.defaultProps = {
  viewMode: 'table',
};
