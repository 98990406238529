import { fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headerContent: {
    width: '100%',
    '& .matchHeadline':{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center', width: '100%',
      '& .matchHeadlineText':{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 20px'
      }
    }
  },
  scoreContent: {
    width: '100%',
    textAlign: 'center'
  },

  container: {
    maxHeight: 440,
  },

  mobileOnly: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
      fontWeight: 'bold',
      width: '30%',
      fontSize: '12px',
      marginRight: 'auto'
    }
  },

  cancelAddEvent: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: '15px'
    }
  },

  addEventTableCell:{
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'fixed',
      top: '50%',
      left: '50%',
      zIndex: '99',
      transform: 'translateY(-50%) translateX(-50%)',
      background: '#fff',
      boxShadow: '0px 3px 21px 0px rgba(0,0,0,0.75);'
    }
  },

  addEventsMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },

  tableResponsive: {
    [theme.breakpoints.down('sm')]: {
      '& thead': {
        display: 'none',
      },
      '& tbody': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
      },
      '& tr': {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '50%',
        width: '100%',
        padding: '30px',
        '& tr:last-of-type': {
          border: 'none'
        }

      },
      '& td': {
        display: 'flex',
        flexDirection: 'row'
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& tr': {
        maxWidth: '100%',

      },
    }

  },

  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
}));
export default useStyles;
