import React, {useState, useEffect, useRef} from 'react';
import {Box} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import useStyles from './../index.style';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import Tooltip from '@material-ui/core/Tooltip';
import SelectMatches from './SelectMatches'
import MatchCell from './MatchCell'
import {getMatchesForOfficialChampionship} from "../../../../redux/actions/OfficialChampionship";
import CmtCard from "../../../../@coremat/CmtCard";

const Matches = ({matches, getMatches, createChampionship, viewDetail}) =>{
  const classes = useStyles();


  const [editDetail, setEditDetail] = useState();
  const [initialMatches, setInitialMatches] = useState(matches);
  const [matchList, setMatchList] = useState([]);
  const [matchListDetail, setMatchListDetail] = useState([]);
  const [removedMatch, setRemovedMatch] = useState(false);

  const [matchesToInsert, setMatchesToInsert] = useState([])
  const [matchesToRemove, setMatchesToRemove] = useState([])
  const myRef = useRef(null);


  useEffect(() => {
    setEditDetail(viewDetail)
    if (viewDetail !== undefined) {
      let matchesId = []
      viewDetail.matches.forEach((item, key) => {
        matchesId.push(item._id);
      })
      setMatchList(matchesId)
      setMatchListDetail(viewDetail.matches)
    }
  }, [viewDetail]);

  const selectMatch = (id, headline, date, homeEmblem, awayEmblem, competition ) =>{
    let newMatch = {
      _id: id,
      headline: headline,
      scheduledDate: date,
      homeTeamEmblemUrl: homeEmblem,
      awayTeamEmblemUrl: awayEmblem,
      competitionName: competition,
    };
    let difMatch = true;

    matchListDetail.forEach((match, key) =>{
      if(match._id === id){
        difMatch = false;
      }
    });

    if(difMatch === true){
      setMatchesToInsert(matchesToInsert => [...matchesToInsert , id]);
      setMatchesToRemove(matchesToRemove.filter((item,key) => item !== id));
      setMatchListDetail(matchListDetail => [...matchListDetail , newMatch]);
      setMatchList(matchList => [...matchList , id]);
      sendMatches([...matchList , id], [...matchesToInsert , id], matchesToRemove.filter((item,key) => item !== id))
    }
  };

  const sendMatches= (currentList, toBeInserted, toBeRemoved) =>{
    getMatches(currentList, toBeInserted, toBeRemoved);
  };


  const removeMatch = (id) =>{
    setMatchList(matchList.filter((item,key) => item !== id));
    setMatchListDetail(matchListDetail.filter((item,key) =>  item._id !== id));
    setMatchesToInsert(matchesToInsert.filter((item,key) => item !== id));
    setMatchesToRemove(matchesToRemove => [...matchesToRemove , id]);
    sendMatches(matchList.filter((item,key) => item !== id), matchesToInsert.filter((item,key) => item !== id), [...matchesToRemove , id])
  };

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

  return(
    <Box>
      <Box style={{display: 'flex', margin: ' 30px 7.5px 15px 7.5px'}}>
        <h2 style={{marginRight: '25px'}}>Matches</h2>
        <Button  variant="contained" color="primary" onClick={executeScroll}>
          Add
        </Button>
      </Box>
      <Box>
        {
          matchListDetail != undefined && matchListDetail.length > 0 ?
          <MatchCell selectedMatches={matchListDetail} onRemove={removeMatch} />
          : <h3 style={{marginLeft: '7.5px', marginBottom: '30px'}}>There are no selected matches. Please add one.</h3>
        }
      </Box>
      {
        viewDetail !== undefined ? <Button style={{marginBottom: '25px'}} variant="contained" color="primary" onClick={()=>createChampionship('update')}>
          Update Championship
        </Button> :
          <Button style={{marginBottom: '25px'}} variant="contained" color="primary" onClick={()=>createChampionship('create')}>
            Create Championship
          </Button>
      }

      <Box ref={myRef}>
        <SelectMatches match={initialMatches} selectMatch={selectMatch}/>
      </Box>
    </Box>
  )
}

export default Matches;
