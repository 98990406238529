import {
  ADD_OFFICIAL_CHAMPHIONSHIPS,
  GET_OFFICIAL_CHAMPHIONSHIPS,
  GET_OFFICIAL_CHAMPHIONSHIPS_MATCHES,
  GET_OFFICIAL_CHAMPHIONSHIPS_LANG,
  GET_OFFICIAL_CHAMPHIONSHIPS_DETAILS,
  PATCH_OFFICIAL_CHAMPHIONSHIPS_DETAILS,
  DELETE_OFFICIAL_CHAMPHIONSHIPS,
  PUBLISH_OFFICIAL_CHAMPHIONSHIPS,
} from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const createOfficialChampionship = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/challenges/official-championship', body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: ADD_OFFICIAL_CHAMPHIONSHIPS, payload: data.data });
        dispatch(fetchError('Official championship created.'));
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getOfficialChampionshipList = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/challenges/bar-championships', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_OFFICIAL_CHAMPHIONSHIPS, payload: data.data });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getOfficialChampionshipDetail = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/challenges/bar-championship-details?challengeId=' + body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_OFFICIAL_CHAMPHIONSHIPS_DETAILS,
          payload: data.data,
        });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const publishOfficialChampionship = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/challenges/publish-official-championship', body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess(data.message));
        dispatch({ type: PUBLISH_OFFICIAL_CHAMPHIONSHIPS, payload: data.data });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const deleteOfficialChampionshipDetail = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/challenges/official-championship', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        data: body,
      })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: DELETE_OFFICIAL_CHAMPHIONSHIPS, payload: data.data });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const patchOfficialChampionshipDetail = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/challenges/official-championship', body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: PATCH_OFFICIAL_CHAMPHIONSHIPS_DETAILS,
          payload: data.data,
        });

        window.location.reload();
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getMatchesForOfficialChampionship = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/matches/admin?matchStatus=future', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_OFFICIAL_CHAMPHIONSHIPS_MATCHES,
          payload: data.data,
        });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getLangForOfficialChampionship = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/languages?status=1', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_OFFICIAL_CHAMPHIONSHIPS_LANG,
          payload: data.data,
        });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
