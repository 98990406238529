import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import useStyles from '../../../index.style';
import AppSelectBox from '../../../../../../components/Common/formElements/AppSelectBox';
import HeaderOptions from './HeaderOptions';
import PropTypes from 'prop-types';

const selectCategories = [
  { id: 12, label: 'None', slug: 'none' },
  { id: 344, label: 'All', slug: 'all' },
  { id: 11, label: 'Starred', slug: 'starred' },
  { id: 1, label: 'Frequent', slug: 'frequent' },
];

const onSelectMatches = (category, matches) => {
  let selectMatches = [];
  switch (category) {
    case 'all': {
      selectMatches = matches;
      break;
    }
    case 'starred': {
      selectMatches = matches.filter(match => match.starred);
      break;
    }
    case 'frequent': {
      selectMatches = matches.filter(match => match.frequent);
      break;
    }
    default:
  }

  return selectMatches.map(match => match.id);
};

const CheckedListHeader = ({ checkedMatches, handleHeaderCheckBox, updateCheckedMatches }) => {
  const [category, setCategory] = useState(selectCategories[0].slug);
  const classes = useStyles();

  const { matchesList, labelsList } = useSelector(({ matchApp }) => matchApp);

  const handleSelectChange = e => {
    setCategory(e.target.value);
    const selectMatches = onSelectMatches(e.target.value, matchesList);
    updateCheckedMatches(selectMatches);
  };

  return (
    <Box className={classes.appContentHeader}>
      <Checkbox
        color="primary"
        indeterminate={checkedMatches.length > 0 && checkedMatches.length < matchesList.length}
        checked={checkedMatches.length > 0 && checkedMatches.length === matchesList.length}
        onChange={e => handleHeaderCheckBox(e.target.checked, matchesList)}
      />

      <AppSelectBox
        id="match-app"
        data={selectCategories}
        value={category}
        fullWidth={false}
        onChange={handleSelectChange}
        className={classes.selectBoxRoot}
        renderRow={(item, index) => (
          <MenuItem key={index} value={item.slug}>
            {item.label}
          </MenuItem>
        )}
      />

      <Box ml="auto" display="flex" alignItems="center">
        <HeaderOptions
          checkedMatches={checkedMatches}
          matchesList={matchesList}
          labelsList={labelsList}
          updateCheckedMatches={updateCheckedMatches}
        />
      </Box>
    </Box>
  );
};

export default CheckedListHeader;

CheckedListHeader.prototype = {
  checkedMatches: PropTypes.array.isRequired,
  handleHeaderCheckBox: PropTypes.func,
  updateCheckedMatches: PropTypes.func,
};
