import React from "react";
import StarIcon from '@material-ui/icons/Star';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';

export const foldersList = [
  { id: 2, name: 'Starred Matches', slug: 'starred', icon: <StarIcon /> },
  { id: 1, name: 'Accepted', slug: 'accepted', icon: <CheckCircleOutlineIcon /> },
  { id: 3, name: 'Pending', slug: 'pending', icon: <PauseCircleOutlineIcon /> },
  { id: 6, name: 'Rejected', slug: 'rejected', icon: <HighlightOffIcon /> },
];
export const labelsList = [
  { id: 1, name: 'UEFA', slug: 'uefa', color: '#FF8C00' },
  { id: 2, name: 'Primera Division', slug: 'primera-division', color: '#00C4B4' },
  { id: 3, name: 'Premiere League', slug: 'premiere-league', color: '#0F9AF7' },
];

export const bars = [
    {
      id: 1457690400,
      name: 'Irish Draft Pub',
      website: 'https://google.com',
      phone: '0789123321',
      email: 'irishpub@pub.com',
      rating: '5',
      country: 'Romania',
      city: 'Bucharest',
      folder: 'accepted',
      profilePic: 'https://via.placeholder.com/150x150',
      dates: [{ date: '27.03.2012 10:30:30', label: 'start_date' }],
      matchType: 'Group C',
      starred: true,
      frequent: true,
      competition: 'Serie A',
      labels: [1],
    },
    {
      id: 3455,
      name: 'Marriot Champions Sport Bar',
      website: 'https://google.com',
      phone: '0789123321',
      email: 'irishpub@pub.com',
      rating: '5',
      country: 'Romania',
      city: 'Bucharest',
      folder: 'accepted',
      profilePic: 'https://via.placeholder.com/150x150',
      dates: [{ date: '27.03.2012 10:30:30', label: 'start_date' }],
      matchType: 'Group C',
      starred: false,
      frequent: true,
      competition: 'Serie A',
      labels: [],
    },
    {
      id: 3855,
      name: 'Halftime Pub',
      website: 'https://google.com',
      phone: '0789123321',
      email: 'irishpub@pub.com',
      rating: '5',
      country: 'Romania',
      city: 'Bucharest',
      folder: 'accepted',
      profilePic: 'https://via.placeholder.com/150x150',
      dates: [{ date: '27.03.2012 10:30:30', label: 'start_date' }],
      matchType: 'Group C',
      starred: false,
      frequent: true,
      competition: 'Serie A',
      labels: [],
    },
    {
      id: 3856,
      name: 'COrni',
      website: 'https://google.com',
      phone: '0789123321',
      email: 'irishpub@pub.com',
      rating: '5',
      country: 'Romania',
      city: 'Bucharest',
      folder: 'accepted',
      profilePic: 'https://via.placeholder.com/150x150',
      dates: [{ date: '27.03.2012 10:30:30', label: 'start_date' }],
      matchType: 'Group C',
      starred: false,
      frequent: true,
      competition: 'Serie A',
      labels: [],
    },
    {
      id: 3857,
      name: 'Beerhub Pub',
      website: 'https://google.com',
      phone: '0789123321',
      email: 'irishpub@pub.com',
      rating: '5',
      country: 'Romania',
      city: 'Bucharest',
      folder: 'accepted',
      profilePic: 'https://via.placeholder.com/150x150',
      dates: [{ date: '27.03.2012 10:30:30', label: 'start_date' }],
      matchType: 'Group C',
      starred: false,
      frequent: true,
      competition: 'Serie A',
      labels: [],
    },
    {
      id: 3858,
      name: 'Puffy pizza',
      website: 'https://google.com',
      phone: '0789123321',
      email: 'irishpub@pub.com',
      rating: '5',
      country: 'Romania',
      city: 'Bucharest',
      folder: 'accepted',
      profilePic: 'https://via.placeholder.com/150x150',
      dates: [{ date: '27.03.2012 10:30:30', label: 'start_date' }],
      matchType: 'Group C',
      starred: false,
      frequent: true,
      competition: 'Serie A',
      labels: [],
    },
    {
      id: 3859,
      name: 'Corner Pub',
      website: 'https://google.com',
      phone: '0789123321',
      email: 'irishpub@pub.com',
      rating: '5',
      country: 'Romania',
      city: 'Bucharest',
      folder: 'accepted',
      profilePic: 'https://via.placeholder.com/150x150',
      dates: [{ date: '27.03.2012 10:30:30', label: 'start_date' }],
      matchType: 'Group C',
      starred: false,
      frequent: true,
      competition: 'Serie A',
      labels: [],
    },
    {
      id: 3860,
      name: 'London Pub',
      website: 'https://google.com',
      phone: '0789123321',
      email: 'irishpub@pub.com',
      rating: '5',
      country: 'Romania',
      city: 'Bucharest',
      folder: 'accepted',
      profilePic: 'https://via.placeholder.com/150x150',
      dates: [{ date: '27.03.2012 10:30:30', label: 'start_date' }],
      matchType: 'Group C',
      starred: false,
      frequent: true,
      competition: 'Serie A',
      labels: [],
    },
    {
      id: 3861,
      name: 'Oxford Pub',
      website: 'https://google.com',
      phone: '0789123321',
      email: 'irishpub@pub.com',
      rating: '5',
      country: 'Romania',
      city: 'Bucharest',
      folder: 'accepted',
      profilePic: 'https://via.placeholder.com/150x150',
      dates: [{ date: '27.03.2012 10:30:30', label: 'start_date' }],
      matchType: 'Group C',
      starred: false,
      frequent: true,
      competition: 'Serie A',
      labels: [],
    },
];
