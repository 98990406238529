import {
  ADD_OFFICIAL_CHAMPHIONSHIPS,
  TOGGLE_SIDEBAR_COLLAPSED,
  GET_OFFICIAL_CHAMPHIONSHIPS,
  GET_OFFICIAL_CHAMPHIONSHIPS_MATCHES,
  GET_OFFICIAL_CHAMPHIONSHIPS_LANG,
  GET_OFFICIAL_CHAMPHIONSHIPS_DETAILS,
  PATCH_OFFICIAL_CHAMPHIONSHIPS_DETAILS,
  DELETE_OFFICIAL_CHAMPHIONSHIPS,
  PUBLISH_OFFICIAL_CHAMPHIONSHIPS,
} from '../..//constants/ActionTypes';
import { Add } from '@material-ui/icons';

const INIT_STATE = {
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    status: 'future',
    competition: '',
    query: '',
  },
  matchesList: [],
  currentMatch: null,
  totalMatches: null,
  counter: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSideBarCollapsed: action.payload ? action.payload : !state.isSideBarCollapsed,
      };
    }
    case ADD_OFFICIAL_CHAMPHIONSHIPS: {
      return {
        ...state,
        createOfficialChampionsip: action.payload,
        successCreate: true,
      };
    }

    case PUBLISH_OFFICIAL_CHAMPHIONSHIPS: {
      return {
        ...state,
        publishOfficialChampionship: action.payload,
      };
    }

    case DELETE_OFFICIAL_CHAMPHIONSHIPS: {
      return {
        ...state,
        deleteOfficialChampionship: action.payload,
      };
    }

    case GET_OFFICIAL_CHAMPHIONSHIPS: {
      return {
        ...state,
        officialChampionshipList: action.payload,
      };
    }

    case GET_OFFICIAL_CHAMPHIONSHIPS_DETAILS: {
      return {
        ...state,
        officialChampionshipDetail: action.payload,
      };
    }

    case PATCH_OFFICIAL_CHAMPHIONSHIPS_DETAILS: {
      return {
        ...state,
        patchOfficialChampionshipDetail: action.payload,
      };
    }

    case GET_OFFICIAL_CHAMPHIONSHIPS_MATCHES: {
      return {
        ...state,
        officialChampionshipMatches: action.payload,
      };
    }

    case GET_OFFICIAL_CHAMPHIONSHIPS_LANG: {
      return {
        ...state,
        lang: action.payload,
      };
    }

    default:
      return state;
  }
};
