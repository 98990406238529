//For expanding sidebar
import {
  ADD_LABEL,
  CREATE_MATCH,
  DELETE_MATCH,
  DELETE_LABEL_ITEM,
  GET_MATCH_COUNTS,
  GET_MATCHES_LIST,
  GET_LABELS_LIST,
  SET_CURRENT_MATCH,
  SET_FILTER_TYPE,
  TOGGLE_SIDEBAR_COLLAPSED,
  UPDATE_MATCH,
  UPDATE_MATCH_LABEL,
  UPDATE_LABEL_ITEM,
  UPDATE_STARRED_STATUS,
  SET_TOP_TEAM,
} from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

var timer;

//For expanding sidebar
export const toggleExpandSidebar = value => {
  return dispatch => {
    dispatch({
      type: TOGGLE_SIDEBAR_COLLAPSED,
      payload: value,
    });
  };
};

//For setting Filtertype
export const setFilterType = filterType => {
  return {
    type: SET_FILTER_TYPE,
    payload: filterType,
  };
};

export const getLabelsList = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/competitions/active', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_LABELS_LIST, payload: data.data });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const setPopularMatch = (id, value) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(
        '/bars/change-broadcast-status',
        {
          matchId: id,
          status: value,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        },
      )
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: SET_TOP_TEAM, payload: data.data });
        dispatch(fetchError(`Set broadcast match success`));
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for adding new label
export const addNewLabel = label => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/match/labels', { label })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: ADD_LABEL, payload: data.data });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//For Deleting Label
export const deleteLabel = labelId => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/match/labels/delete', { labelId })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: DELETE_LABEL_ITEM, payload: labelId });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//For Editing Label
export const updateLabel = label => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/match/labels', { label })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_LABEL_ITEM, payload: label });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for getting matches list
export const getMatchesList = (params, counter) => {
  return dispatch => {
    dispatch(fetchStart());

    // Prevent to many calls on api when search
    const delay = params.query ? 500 : 0;
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (params.query) {
        axios
          .get('/matches/admin?query=' + params.query, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(data => {
            let folderMatches;
            folderMatches = data.data.matches.sort((a, b) => new Date(b.scheduledDate) - new Date(a.scheduledDate));
            let newCount = { ...counter };
            let listMatches = {
              folderMatches: folderMatches,
              counter: newCount,
            };

            dispatch(fetchSuccess());
            dispatch({ type: GET_MATCHES_LIST, payload: listMatches });
          })
          .catch(error => {
            if (error.response.status != 401) {
              dispatch(fetchError('Something went wrong'));
            }
          });
      } else if (params.competition) {
        axios
          .get('/matches/admin?competitions=' + params.competition, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(data => {
            let folderMatches;
            folderMatches = data.data.matches.sort((a, b) => new Date(b.scheduledDate) - new Date(a.scheduledDate));
            let newCount = { ...counter };
            folderMatches.sort(function(a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(a.scheduledDate) - new Date(b.scheduledDate);
            });
            let listMatches = {
              folderMatches: folderMatches,
              counter: newCount,
            };

            dispatch(fetchSuccess());
            dispatch({ type: GET_MATCHES_LIST, payload: listMatches });
          })
          .catch(error => {
            if (error.response.status != 401) {
              dispatch(fetchError('Something went wrong'));
            }
          });
      } else {
        axios
          .get('/matches/admin?matchStatus=future', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(data => {
            const { status, competition, query } = params;
            let folderMatches = [];
            let newCount = { ...counter };

            if (status === 'future') {
              folderMatches = data.data.matches.sort((a, b) => b.scheduledDate - a.scheduledDate);
            }

            newCount.folders[1] = data.data.count;

            let listMatches = {
              folderMatches: folderMatches,
              counter: newCount,
            };

            dispatch(fetchSuccess());
            if (status === 'future') {
              dispatch({ type: GET_MATCHES_LIST, payload: listMatches });
            }
            dispatch({ type: GET_MATCH_COUNTS, payload: { ...newCount } });
          })
          .catch(error => {
            if (error.response.status != 401) {
              dispatch(fetchError('Something went wrong'));
            }
          });
      }
    }, delay);
  };
};

export const setCurrentMatch = match => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_MATCH,
      payload: match,
    });
  };
};

//for creating new match
export const createMatch = match => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/match', { match })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: CREATE_MATCH, payload: data.data });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for updating match through detail page
export const onUpdateMatch = match => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/match', { match })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_MATCH, payload: match });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for updating matches starred status(through listing)
export const updateStarredStatus = (matchIds, status) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/match/update-starred', { matchIds, status })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_STARRED_STATUS,
          payload: { matchIds, status },
        });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for updating mails folder(through listing)
export const deleteMatch = matchIds => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/match/delete', { matchIds })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: DELETE_MATCH, payload: matchIds });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for updating matches label(through listing)
export const updateMatchesLabel = (matchIds, label) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/match/update-label', { matchIds, label })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_MATCH_LABEL, payload: data.data });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

//for getting match categories(in sidebar) count
export const getMatchCounts = () => {
  return dispatch => {
    axios
      .get('/match/counter')
      .then(data => {
        dispatch({ type: GET_MATCH_COUNTS, payload: data.data });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
