import React, { useContext, useEffect, useState } from 'react';
import ListTableView from './ListTableView';
import ListGridView from './ListGridView';
import { useDispatch, useSelector } from 'react-redux';
import { getMatchesList } from '../../../../redux/actions/MatchApp';
import PropTypes from 'prop-types';
import MatchesMsg from './MatchesMsg';
import { Box } from '@material-ui/core';
import useStyles from '../index.style';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getMatchContainerHeight } from '../../../../constants/AppConstants';
import AppContext from '../../../../components/contextProvider/AppContextProvider/AppContext';
import EmptyMatchResult from './EmptyMatchResult';
import StarIcon from '@material-ui/icons/Star';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';

export const foldersList = [
  { id: 1, name: 'Future', slug: 'future', icon: <AccessTimeIcon /> },
  { id: 2, name: 'Live', slug: 'live', icon: <TimelapseIcon /> },
  { id: 3, name: 'Ended', slug: 'past', icon: <AlarmOnIcon /> },
];

export const counter = {
  folders:{
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0
  },
  labels: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0
  }
};
const MatchesList = ({ width, viewMode, onShowMatchDetail, onClickEditMatch }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const { filterType, matchesList } = useSelector(({ matchApp }) => matchApp);
  const [checkedMatches, setCheckedMatches] = useState([]);
  const [showMsg, setShowMsg] = useState(true);

  useEffect(() => {
    dispatch(getMatchesList(filterType, counter));
  }, [filterType, dispatch]);

  const handleCellCheckBox = (isChecked, id) => {
    if (isChecked) {
      setCheckedMatches(checkedMatches.concat(id));
    } else {
      setCheckedMatches(checkedMatches.filter(matchId => matchId !== id));
    }
  };

  const toggleMsgShow = () => {
    setShowMsg(!showMsg);
  };

  const handleHeaderCheckBox = isChecked => {
    if (isChecked) {
      const ids = matchesList.map(match => match.id);
      updateCheckedMatches(ids);
    } else {
      updateCheckedMatches([]);
    }
  };

  const updateCheckedMatches = matchIds => {
    setCheckedMatches(matchIds);
  };

  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter),
  });
  return matchesList != undefined && matchesList.length > 0 ? (
    <Box className={classes.inBuildAppMainContent}>
        {/*{showMsg && <MatchesMsg matchesList={matchesList} toggleMsgShow={toggleMsgShow} />}*/}
        {viewMode === 'table' ? (
          <ListTableView
            checkedMatches={checkedMatches}
            handleCellCheckBox={handleCellCheckBox}
            handleHeaderCheckBox={handleHeaderCheckBox}
            updateCheckedMatches={updateCheckedMatches}
            onShowMatchDetail={onShowMatchDetail}
            onClickEditMatch={onClickEditMatch}
            filterType={filterType.status}
            width={width}
          />
        ) : (
          <ListGridView width={width} filterType={filterType} onShowMatchDetail={onShowMatchDetail} onClickEditMatch={onClickEditMatch} />
        )}
      {/*</PerfectScrollbar>*/}
    </Box>
  ) : (
    <Box className={classes.inBuildAppMainContent}>
      <EmptyMatchResult />
    </Box>
  );
};

export default MatchesList;

MatchesList.prototype = {
  viewMode: PropTypes.string,
  onShowMatchDetail: PropTypes.func,
  onClickEditMatch: PropTypes.func,
};

MatchesList.defaultProps = {
  viewMode: 'table',
};
