import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import CmtAvatar from '../../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import useStyles from '../MatchCell.style';
import PropTypes from 'prop-types';
import MatchCellOptions from '../ListTableView/MatchCellOptions';
import Moment from 'moment';
import { setPopularMatch } from '../../../../../redux/actions/MatchApp';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const MatchCell = ({ filterType, match, onShowMatchDetail, onClickEditMatch }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [newPopular, setNewPopular] = useState(match.popular);

  const onClickPopularMatch = e => {
    setNewPopular(!newPopular);
    dispatch(setPopularMatch(match._id, !newPopular));
  };

  const classes = useStyles();

  const { headline, homeTeamEmblemUrl, awayTeamEmblemUrl, scheduledDate, competitionName, matchType, profilePic } = match;
  return (
    <Box className={classes.gridMatchCell}>
      <Box className={classes.gridMatchCellHeader} display="flex" mb={3}>
        <Box width={{ md: 'calc(100% - 56px)', sm: '100%' }} className={classes.flexWrap} display="flex" alignItems="center">
          <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
            <Box
              mr={4}
              style={{
                width: '30px',
                overflow: 'hidden',
                marginRight: '0',
                borderRight: '1px solid #fff',
              }}>
              <CmtAvatar size={60} src={homeTeamEmblemUrl} alt={headline} />
            </Box>
            <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
              <CmtAvatar size={60} style={{ transform: 'translateX(-30px)' }} src={awayTeamEmblemUrl} alt={headline} />
            </Box>
          </Box>

          <Box width={{ sm: 'calc(100% - 56px)', xs: '100%' }}>
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {headline}
            </Typography>
            {competitionName && (
              <Box mb={2} component="p" className={classes.textTruncate}>
                {competitionName}
              </Box>
            )}
          </Box>
        </Box>
        <Box ml={{ sm: 'auto' }} onClick={e => e.stopPropagation()}>
          <MatchCellOptions
            filterType={filterType}
            popularType={newPopular}
            match={match}
            onClickTopMatch={onClickPopularMatch}
            onClickEditMatch={() => {
              history.push('/match/' + match._id);
            }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box mb={2} component="p">
          {Moment(scheduledDate).format('H:mm - D MMM yyyy')}
        </Box>
      </Box>
    </Box>
  );
};

export default MatchCell;

MatchCell.prototype = {
  match: PropTypes.object.isRequired,
  onShowMatchDetail: PropTypes.func,
  onClickEditMatch: PropTypes.func,
};
