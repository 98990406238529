import React from 'react';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportMatches = ({ children, data }) => {
  const matches = data ? []
  //   data.map(item => {
  //   item.dateStrings = item.dates.map(match => match.date).join(',');
  //   return item;
  // })
    : [];
  return (
    <ExcelFile element={children}>
      <ExcelSheet data={matches} name="Matches">
        <ExcelColumn label="Id" value="id" />
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="MatchType" value="matchType" />
        <ExcelColumn label="Country" value="country" />
        <ExcelColumn label="Dates" value="dateStrings" />
        <ExcelColumn label="Competition" value="competition" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExportMatches;
