import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import useStyles from './../index.style';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';

const ChampionshipDetails = ({
  langList,
  sendDaily,
  sendWeekly,
  sendAllTime,
  sendQr,
  sendLogo,
  sendCover,
  getHowItWorks,
  sendName,
  sendStake,
  viewDetail,
}) => {
  const classes = useStyles();

  // const lang = [
  //   "en",
  //   "ro",
  //   "it",
  //   "de",
  //   "fr",
  //   "es"
  // ];

  const lang = langList;
  const [editDetail, setEditDetail] = useState();
  const [logo, setLogo] = useState(null);
  const [previewLogo, setPreviewLogo] = useState(null);
  const [cover, setCover] = useState(null);
  const [previewCover, setPreviewCover] = useState(null);
  const [allTime, setAllTime] = useState(1);
  const [weekly, setWeekly] = useState(0);
  const [daily, setDaily] = useState(0);
  const [qrCodeEligible, setQrCodeEligible] = useState(false);

  const [description, setDescription] = useState(() => {
    let state = [];
    lang.forEach((item, key) => {
      let array = {
        text: '',
        language: item,
      };
      state.push(array);
    });
    return state;
  });

  useEffect(() => {
    setEditDetail(viewDetail);
    if (viewDetail !== undefined) {
      setDaily(viewDetail.prizeTypesCounts == undefined ? 0 : viewDetail.prizeTypesCounts.daily);
      sendDaily(viewDetail.prizeTypesCounts == undefined ? 0 : viewDetail.prizeTypesCounts.daily);
      setWeekly(viewDetail.prizeTypesCounts == undefined ? 0 : viewDetail.prizeTypesCounts.weekly);
      sendWeekly(viewDetail.prizeTypesCounts == undefined ? 0 : viewDetail.prizeTypesCounts.weekly);
      setAllTime(viewDetail.prizeTypesCounts == undefined ? 0 : viewDetail.prizeTypesCounts.allTime);
      sendAllTime(viewDetail.prizeTypesCounts == undefined ? 0 : viewDetail.prizeTypesCounts.allTime);
      setQrCodeEligible(viewDetail.qrCodeEligible == undefined ? false : viewDetail.qrCodeEligible);
      sendQr(viewDetail.qrCodeEligible == undefined ? false : viewDetail.qrCodeEligible);
      setPreviewLogo(viewDetail.logo);
      setPreviewCover(viewDetail.cover);
      // setDescription(viewDetail.howItWorks);
      let newHowItWorks = [];
      viewDetail.howItWorks.forEach((langH, key) => {
        newHowItWorks.push(langH);
      });
      let newLangHowItWorks = [];

      lang.forEach((langH, key) => {
        newLangHowItWorks.push({
          text: '',
          language: langH,
        });
      });

      var newArr = newLangHowItWorks.reduce(
        (acc, eachArr2Elem) => {
          if (
            newHowItWorks.findIndex(
              eachArr1Elem => eachArr1Elem.language === eachArr2Elem.language && eachArr1Elem.text.length >= 1,
            ) === -1
          ) {
            acc.push(eachArr2Elem);
          }
          return acc;
        },
        [...newHowItWorks],
      );
      setDescription(newArr);
    }
  }, [viewDetail]);

  useEffect(() => {
    if (viewDetail === undefined) {
      if (langList.length > 0) {
        setDescription(() => {
          let state = [];
          lang.forEach((item, key) => {
            let array = {
              text: '',
              language: item,
            };
            state.push(array);
          });
          return state;
        });
      }
    }
  }, [langList]);

  const handleChange = (e, language, key) => {
    // let descriptionNew = {
    //   text: e.target.value,
    //   language: lang,
    // };
    let state = [];
    lang.forEach((item, key) => {
      let array = {
        text: e.target.value,
        language: item,
      };
      state.push(array);
    });

    setDescription(state);
    getHowItWorks(state);
  };

  const handleLogo = e => {
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
    setLogo(e.target.files[0]);
    sendLogo(e.target.files[0]);
  };

  const handleCover = e => {
    setPreviewCover(URL.createObjectURL(e.target.files[0]));
    setCover(e.target.files[0]);
    sendCover(e.target.files[0]);
  };

  const handleDaily = e => {
    setDaily(e);
    sendDaily(e);
  };

  const handleWeekly = e => {
    setWeekly(e);
    sendWeekly(e);
  };

  const handleAllTime = e => {
    setAllTime(e);
    sendAllTime(e);
  };

  const handleQr = e => {
    setQrCodeEligible(e);
    sendQr(e);
  };
  return (
    <Box>
      <Box className={classes.headOfficial}>
        <Box className={classes.headOfficialLeft}>
          {editDetail !== undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendName(e.target.value)}
              defaultValue={editDetail.name}
              className={classes.textFieldHead}
              label="Championship Name"
              variant="outlined"
            />
          )}
          {editDetail === undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendName(e.target.value)}
              className={classes.textFieldHead}
              label="Championship Name"
              variant="outlined"
            />
          )}
          {editDetail !== undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendStake(e.target.value)}
              defaultValue={editDetail.stake}
              className={classes.textFieldHead}
              label="Official stake"
              variant="outlined"
            />
          )}
          {editDetail === undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendStake(e.target.value)}
              className={classes.textFieldHead}
              label="Official stake"
              variant="outlined"
            />
          )}
        </Box>
        <Box className={classes.headOfficialRight}>
          <Box className={classes.logoCover}>
            <Box className={classes.imagePlaceholder}>
              {previewLogo !== null ? <img src={previewLogo} className={classes.imageLogo} /> : null}
            </Box>
            <input
              accept="image/*"
              onChange={handleLogo}
              className={classes.hiddenInput}
              id="contained-button-logo"
              multiple
              type="file"
            />
            <label htmlFor="contained-button-logo">
              <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
                Upload Logo
              </Button>
            </label>
          </Box>
          <Box className={classes.logoCover}>
            <Box className={classes.imagePlaceholder}>
              {previewCover !== null ? <img src={previewCover} className={classes.imageLogo} /> : null}
            </Box>
            <input
              accept="image/*"
              onChange={handleCover}
              className={classes.hiddenInput}
              id="contained-button-cover"
              multiple
              type="file"
            />
            <label htmlFor="contained-button-cover">
              <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
                Upload Cover
              </Button>
            </label>
          </Box>
        </Box>
      </Box>
      <Box>
        {editDetail !== undefined &&
          description !== undefined &&
          description.map((item, key) => {
            if (key === 0) {
              return (
                <TextField
                  id="outlined-basic"
                  key={key}
                  defaultValue={item.text}
                  onChange={e => handleChange(e, item.language, key)}
                  multiline={true}
                  className={classes.Textarea50}
                  label={'How it works'}
                  variant="outlined"
                />
              );
            }
          })}
      </Box>
      <Box>
        {editDetail === undefined &&
          description !== undefined &&
          description.map((item, key) => {
            if (key === 0) {
              return (
                <TextField
                  id="outlined-basic"
                  key={key}
                  onChange={e => handleChange(e, item.language, key)}
                  multiline={true}
                  className={classes.Textarea50}
                  label={'How it works'}
                  variant="outlined"
                />
              );
            }
          })}
      </Box>
      <Box style={{ display: 'block', margin: '10px 7.5px' }}>
        <h2>Type of Prizes</h2>
      </Box>
      <Box className={classes.fullBoxwidth}>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ maxWidth: '170px', width: '100%' }}
            onChange={e => {
              handleDaily(e.target.value);
            }}
            InputProps={{ inputProps: { min: 0 } }}
            className={classes.TextareaNumber}
            value={daily}
            type="number"
            label="Prizes per day"
            variant="outlined"
          />
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ maxWidth: '170px', width: '100%' }}
            onChange={e => {
              handleWeekly(e.target.value);
            }}
            InputProps={{ inputProps: { min: 0 } }}
            className={classes.TextareaNumber}
            value={weekly}
            type="number"
            label="Prizes per week"
            variant="outlined"
          />
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ maxWidth: '170px', width: '100%' }}
            onChange={e => {
              handleAllTime(e.target.value);
            }}
            InputProps={{ inputProps: { min: 1 } }}
            className={classes.TextareaNumber}
            value={allTime}
            type="number"
            label="All time prizes"
            variant="outlined"
          />
        </Box>
        <Box className={classes.fakeBorder}>
          <Box className={classes.label}>qrCodeEligible</Box>
          <p>
            {' '}
            <Switch
              checked={qrCodeEligible}
              onChange={e => {
                handleQr(!qrCodeEligible);
              }}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default ChampionshipDetails;
