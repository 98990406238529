import { GET_BAR_DETAIL, EDIT_BAR_DETAIL } from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { setAuthBar } from './Auth';

export const getBarDetail = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/bars/' + JSON.parse(localStorage.getItem('login')).barId, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess());
        localStorage.setItem('bar', JSON.stringify(data.data));
        dispatch(setAuthBar(data.data));
        dispatch({ type: GET_BAR_DETAIL, payload: data.data });
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
export const editBarDetail = data => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/bars/', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: EDIT_BAR_DETAIL, payload: data.data });
        dispatch(getBarDetail());
      })
      .catch(error => {
        if (error.response.status != 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
