import { SCAN_QR_CODE, VALID_QR_CODE } from '../../constants/ActionTypes';
const INIT_STATE = {
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    selectedFolder: 'accepted',
    selectedLabel: '',
    searchText: '',
  },
  barsList: [],
  currentBar: null,
  totalBars: null,
  counter: null,
  qrDetail: null,
  isValid: false,
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SCAN_QR_CODE: {
      return {
        ...state,
        qrDetail: action.payload,
      };
    }

    case VALID_QR_CODE: {
      return {
        ...state,
        isValid: action.payload,
      };
    }

    default:
      return state;
  }
};
