import React from 'react';
import CmtVertical from '../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';
import { PostAdd } from '@material-ui/icons';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import GavelIcon from '@material-ui/icons/Gavel';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import LanguageIcon from '@material-ui/icons/Language';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const navigationMenus = [
    {
      name: <IntlMessages id={'sidebar.content'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'pages.barPage'} />,
          icon: <PostAdd />,
          type: 'item',
          link: '/edit-bar',
        },
        {
          name: <IntlMessages id={'pages.matchPage'} />,
          type: 'item',
          icon: <SportsSoccerIcon />,
          link: '/matches',
        },
        // {
        //   name: <IntlMessages id={'pages.competitionsPage'} />,
        //   type: 'item',
        //   icon: <BookmarksIcon />,
        //   link: '/competitions',
        // },
        {
          name: <IntlMessages id={'pages.officialChampionshipsPage'} />,
          type: 'item',
          icon: <BeenhereIcon />,
          link: '/official-championships',
        },

        {
          name: <IntlMessages id={'pages.qrCode'} />,
          type: 'item',
          icon: <CameraAltIcon />,
          link: '/scan-qr-code',
        },
        // {
        //   name: <IntlMessages id={'pages.barPage'} />,
        //   type: 'item',
        //   icon: <LocalDrinkIcon />,
        //   link: '/bars',
        // },
        // {
        //   name: <IntlMessages id={'pages.teamsPage'} />,
        //   type: 'item',
        //   icon: <PeopleOutlineRoundedIcon />,
        //   link: '/teams',
        // },
        // {
        //   name: <IntlMessages id={'pages.rulesPage'} />,
        //   type: 'item',
        //   icon: <GavelIcon />,
        //   link: '/rules',
        // },
        // {
        //   name: <IntlMessages id={'pages.languagesPage'} />,
        //   type: 'item',
        //   icon: <LanguageIcon />,
        //   link: '/translations',
        // },
      ],
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
