import { SCAN_QR_CODE, VALID_QR_CODE } from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const scanQrCode = (data, isValid) => {
  if (isValid === true) {
    return;
  }
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/challenge-winners/verify', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: SCAN_QR_CODE,
          payload: 'QR code valid – win ' + data.data.challengeRanking.position + ' place in ' + data.data.challenge.name,
        });
        dispatch({ type: VALID_QR_CODE, payload: true });
      })
      .catch(error => {
        if (error) {
          if (error.response.status === 400) {
            dispatch(fetchSuccess());
            dispatch({
              type: SCAN_QR_CODE,
              payload: error.response.data.message,
            });
            dispatch({ type: VALID_QR_CODE, payload: false });
          }
        }
      });
  };
};
