import React, {useContext, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import CmtGridView from '../../../../../@coremat/CmtGridView';
import MatchCell from './MatchCell';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import PerfectScrollbar from "react-perfect-scrollbar";
import useStyles from "../../index.style";
import {getMatchContainerHeight} from "../../../../../constants/AppConstants";
import AppContext
  from "../../../../../components/contextProvider/AppContextProvider/AppContext";
import TablePagination from "@material-ui/core/TablePagination";

const ListGridView = ({ width, onShowMatchDetail, onClickEditMatch }) => {
  const { matchesList } = useSelector(({ matchApp }) => matchApp);
  const [ matchesListTable, setMatchesListTable] = useState({});
  const { showFooter } = useContext(AppContext);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  useEffect(() => {
    setMatchesListTable(matchesList.slice(0, 50))
    setCount(matchesList.length);
    setPage(0)
  }, [matchesList]);



  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter) + 100,
  });

  const handleChangePage = (event, newPage) =>{
    setMatchesListTable(matchesList.slice(newPage*rowsPerPage, (newPage*rowsPerPage)+rowsPerPage) ||{});
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event =>{
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setMatchesListTable(matchesList.slice(page, rowsPerPage) ||{});
  };

  return (
    <Box px={6} py={{ xs: 8, xl: 10 }}  style={{paddingBottom: '0px'}}>
      <PerfectScrollbar className={classes.perfectScrollbarMatchCon}>
        <CmtGridView
          border={true}
          itemPadding={18}
          responsive={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 3,
          }}
          data={matchesListTable}
          renderRow={(item, index) => (
            <MatchCell key={index} match={item} onShowMatchDetail={onShowMatchDetail} onClickEditMatch={onClickEditMatch} />
          )}
        />
      </PerfectScrollbar>
      <TablePagination
        component="div"
        style={{order: '9999', width: '100%'}}
        count={count}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default ListGridView;

ListGridView.prototype = {
  onShowMatchDetail: PropTypes.func,
  onClickEditMatch: PropTypes.func,
};
