import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import useStyles from './../index.style';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import Tooltip from '@material-ui/core/Tooltip';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Moment from 'moment';

const MatchCell = ({ selectedMatches, onRemove }) => {
  const classes = useStyles();

  return (
    <Box className={classes.selectedMatchesList}>
      {selectedMatches.map((match, id) => {
        return (
          <Box key={id} className={classes.selectListMatchRow}>
            <Box>
              <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                <Box
                  mr={4}
                  style={{
                    width: '30px',
                    overflow: 'hidden',
                    marginRight: '0',
                    borderRight: '1px solid #fff',
                  }}>
                  <CmtAvatar size={60} src={match.homeTeamEmblemUrl} alt={match.headline} />
                </Box>
                <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
                  <CmtAvatar
                    size={60}
                    style={{ transform: 'translateX(-30px)' }}
                    src={match.awayTeamEmblemUrl}
                    alt={match.headline}
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <h3 style={{ fontWeight: 'bold' }}>{match.headline}</h3>
              </Box>
              <Box>{Moment(match.scheduledDate).format('H:mm - D MMM yyyy')}</Box>
              <Box>{match.competitionName}</Box>
            </Box>
            <Button
              style={{ marginLeft: 'auto' }}
              variant="contained"
              color="primary"
              disabled={match.status === 'FT'}
              onClick={() => onRemove(match._id)}>
              Remove
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

export default MatchCell;
